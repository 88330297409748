import React, { useState } from "react";
//import ProjectFilter from "./Project/ProjectFilter"
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import AppModal from "../Commons/AppModal";
import Multiselect from "multiselect-react-dropdown";
import Ws from "../Axios/Axios";
import { getToken } from "../../Utils/Common";
// core components

function AnswerItem(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [newlist, setNewList] = useState();

  const [token, setToken] = useState(getToken());

  const deleteHandler = () =>{
    props.onDelete(props.id)
  }

  const addToListHandler = () =>{
    toggle();
    props.onAddToList(props.id,newlist)
  }

  function onSelectNewAnswerList(selectedList, selectedItem) {
    //console.log(selectedItem);
    //console.log(selectedItem["id"]);
    setNewList(selectedItem["id"]);
  }

  return (
    <tr>
      <th scope="row">
        <Media className="align-items-center">
          <Media>
            <span className="mb-0 text-sm">{props.project_name}</span>
          </Media>
        </Media>
      </th>
      <td><Media className="align-items-center">
          <Media>
            <span className="mb-0 text-sm">{props.company_name}</span>
          </Media>
        </Media></td>
      <td width="20%" style={{backgroundColor:`#${props.answer_color}`}}>{props.answer}</td>
      <td>{props.comments}</td>
      <td>{props.contact_log}</td>
    </tr>
  );
}

export default AnswerItem;
