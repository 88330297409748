
import React, { useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
  } from "reactstrap";
  import Alert from "../Commons/Alert";
  import {
    getToken
  } from "../../Utils/Common";
  import Ws from "../Axios/Axios";
  
  const NewUser = () => {
    const [newUser, setNewUser] = useState({
        Name: "",
        Password: "",
        Email: ""

    });
    const [token, setToken] = useState(getToken());
    const [isNameFilled, setIsNameFilled] = useState(false);
    const [isEmailFilled, setIsEmailFilled] = useState(false);
    const [isPasswordFilled, setIsPasswordFilled] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const [visibleAlert, setAlertVisible] = useState(false);
    
    const [alertData, setAlertData] = useState({
        title: "",
        message: "",
        type: "",
    });

    const changeNewNameHandler = (event) => {
        setNewUser((prevState) => {
          return { ...prevState, Name: event.target.value };
        });
        if (event.target.value.trim().length > 0) {
          setIsNameFilled(true);
          setShowErrors(false);
        } else {
          setIsNameFilled(false);
          setShowErrors(true);
        }
      };

      const changeNewEmailHandler = (event) => {
        setNewUser((prevState) => {
          return { ...prevState, Email: event.target.value };
        });
        if (event.target.value.trim().length > 0) {
            setIsEmailFilled(true);
          setShowErrors(false);
        } else {
            
            setIsEmailFilled(false);
          setShowErrors(true);
        }
      };
      const changeNewPasswordHandler = (event) => {
        setNewUser((prevState) => {
          return { ...prevState, Password: event.target.value };
        });
        if (event.target.value.trim().length > 0) {
            setIsPasswordFilled(true);
          setShowErrors(false);
        } else {
            setIsPasswordFilled(false);
          setShowErrors(true);
        }
      };

      const handleSubmission = () => {
        if (
          !isEmailFilled ||
          !isNameFilled ||
          !isPasswordFilled
        ) {
          setShowErrors(true);
          return;
        }
    
        const Ws1 = Ws(true, token);
        Ws1.post("profile/", {
          name: newUser.Name,
          email: newUser.Email,
          password: newUser.Password
        })
          .then((response) => {
            // console.log(response.data);
            setNewUser({
                Name: "",
                Password: "",
                Email: ""
            });
            setAlertData({
              title: "New User",
              message: "user " + newUser.Name + " created correctly",
              type: "success",
            });
          })
          .catch((error) => {
            // console.log(error);
            // console.log(error.response);
            // console.log(error.response.data);
            // console.log(error.response.data["non_field_errors"]);
            // console.log(Object.getOwnPropertyNames(error.response.data));
            let response = "";
            if (error.response.data[0]) response = error.response.data[0];
            
            if (error.response.data["non_field_errors"])
              response = error.response.data["non_field_errors"];
              
            if (error.response.data["email"])
              response = error.response.data["email"];
    
              setAlertData({
              title: "New User",
              message: "There was a problem creating the user",
              type: "danger",
            });
            if (error.response.status === 400)
            setAlertData((prevState) => {
                return {
                  ...prevState,
                  message: [...(prevState.message + ". " + response)],
                };
              });
          });
        
          handleVisible();
      };
      const handleVisible = () => {
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 5000);
      };
    return (
      <>
      <Alert
        title={alertData.title}
        type={alertData.type}
        isOpen={visibleAlert}
      >
        {alertData.message}
      </Alert>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2">
              <p>Create New User</p>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Name" type="text" 
                    onChange={changeNewNameHandler}
                    value={newUser.Name}
                    className={!isNameFilled & showErrors ? "is-invalid" : ""}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      onChange={changeNewEmailHandler}
                      value={newUser.Email}
                      className={!isEmailFilled & showErrors ? "is-invalid" : ""}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      onChange={changeNewPasswordHandler}
                      className={!isPasswordFilled & showErrors ? "is-invalid" : ""}
                      value={newUser.Password}
                    />
                  </InputGroup>
                </FormGroup>
               
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={handleSubmission}>
                    Create account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  };
  
  export default NewUser;
  