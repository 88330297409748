import Project from "./components/Project/Project";
import Company from "./components/Company/Company";
import Contact from "./components/Contact/Contact";
import List from "./components/List/List";
import User from "./components/User/NewUser";


var routes = [
  {
    path: "/project",
    name: "Project",
    icon: "ni ni-archive-2 text-primary",
    component: Project,
    layout: "",
  },
  {
    path: "/company",
    name: "Company",
    icon: "ni ni-building text-primary",
    component: Company,
    layout: ""
  },
  {
    path: "/contact",
    name: "Contacts",
    icon: "ni ni-badge text-primary",
    component: Contact,
    layout: ""
  },
  {
    path: "/List",
    name: "List",
    icon: "ni ni-bullet-list-67 text-primary",
    component: List,
    layout: ""
  },
  {
    path: "/user",
    name: "Create User",
    icon: "ni ni-circle-08 text-primary",
    component: User,
    layout: ""
  },
  /*{
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },*/
];
export default routes;
