import React, { useState, useEffect } from "react";

// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "../Headers/Header";
import Multiselect from "multiselect-react-dropdown";
import AppModal from "../Commons/AppModal";
import Ws from "../Axios/Axios";
import { getToken, getUserId } from "../../Utils/Common";

const ListSearch = (props) => {
  //modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  //

  const [token, setToken] = useState(getToken());
  const [filter, setFilter] = useState({
    enteredName: "",
  });

  const [newName, setNewName] = useState("");
  const [isValid, setIsValid] = useState(true);

  const searchHandler = (e) => {
    e.preventDefault();
    props.onFilter(filter);
  };

  const handleSubmission = () => {
    if (newName.trim().length === 0) {
      setIsValid(false);
      return;
    }
    const Ws1 = Ws(true, token);
    Ws1.post("list/", {
      name: newName,
      user_profile: getUserId(),
    })
      .then((response) => {
        // console.log(response.data);
        props.onFilter(filter);
        props.onsetAlertData({
          title: "List",
          message: "List " + newName + " created correctly",
          type: "success",
        });
      })
      .catch((error) => {
        let response = "";
        if (error.response.data[0]) response = error.response.data[0];

        if (error.response.data["non_field_errors"])
          response = error.response.data["non_field_errors"];

        props.onsetAlertData({
          title: "List",
          message: "There was a problem creating the List",
          type: "danger",
        });
        if (error.response.status === 400)
          props.onsetAlertData((prevState) => {
            return {
              ...prevState,
              message: [...(prevState.message + ". " + response)],
            };
          });
      });
    toggle();
    props.onHandleVisible();
  };

  const changeNameHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredName: event.target.value };
    });
  };

  const changeNewNameHandler = (event) => {
    setNewName(event.target.value);
    if (event.target.value.trim().length > 0) {
      setIsValid(true);
    }
  };

  return (
    <Header className="pb-6">
      <Form>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="contactName">
                Name
              </label>
              <Input
                id="contactName"
                placeholder="Name"
                type="text"
                value={filter.enteredName}
                onChange={changeNameHandler}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12" className="text-right">
            <Button
              className="btn-icon btn-3 btn-circle"
              color="primary"
              type="button"
              data-placement="top"
              id="tooltipadd"
              onClick={toggle}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
            </Button>
            <UncontrolledTooltip delay={0} placement="top" target="tooltipadd">
              Add New List
            </UncontrolledTooltip>
            <AppModal
              toggle={toggle}
              modal={modal}
              title={"New List"}
              handleSubmission={handleSubmission}
              action={"Create"}
            >
              <label className="form-control-label" htmlFor="listName">
                Name
              </label>
              <Input
                type="text"
                name="listName"
                className={!isValid ? "is-invalid" : ""}
                onChange={changeNewNameHandler}
              />
            </AppModal>
            <Button
              className="btn-icon btn-3"
              color="primary"
              type="button"
              onClick={searchHandler}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-zoom-split-in" />
              </span>
              <span className="btn-inner--text">Search</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Header>
  );
};

export default ListSearch;
