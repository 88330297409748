import React, { useState,useEffect } from "react";

// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "../Headers/Header";
import AppModal from "../Commons/AppModal";
import Multiselect from "multiselect-react-dropdown";
import Ws from "../Axios/Axios";
import { getToken } from "../../Utils/Common";
import FormBT from 'react-bootstrap/Form';

const CompanySearch = (props) => {
  const [newCompany, setNewCompany] = useState({
    Name: "",
    Project: "",
    Description: "",
    Employees: "",
    Patents: "",
    Country: "",
    Link: "",
    Comments: null,
    Answer: null,
    Answer_Color: null,
    Log: null

  });
  
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [isNameFilled, setIsNameFilled] = useState(false);
  const [isCountryFilled, setIsCountryFilled] = useState(false);
  const [isLinkFilled, setIsLinkFilled] = useState(false);
  const [isEmployeesFilled, setIsEmployeesFilled] = useState(false);
  const [isPatentsFilled, setIsPatentsFilled] = useState(false);
  const [isDescriptionFilled, setIsDescriptionFilled] = useState(false);
  //const [isCommentsFilled, setIsCommentsFilled] = useState(false);
  //const [isAnswerFilled, setIsAnswerFilled] = useState(false);
  //const [isLogFilled, setIsLogFilled] = useState(false);

  
  const [showErrors, setShowErrors] = useState(false);

  //modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [token, setToken] = useState(getToken());

  
  const handleSubmission = () => {
    if (
      !isProjectSelected ||
      !isNameFilled ||
      !isCountryFilled ||
      !isLinkFilled ||
      !isEmployeesFilled ||
      !isPatentsFilled ||
      !isDescriptionFilled
      //!isCommentsFilled||
      //!isAnswerFilled||
      //!isLogFilled
    ) {
      setShowErrors(true);
      return;
    }

    const Ws1 = Ws(true, token);
    Ws1.post("company/", {
      name: newCompany.Name,
      country: newCompany.Country,
      description: newCompany.Description,
      link: newCompany.Link,
      employee_number: newCompany.Employees,
      patents_numer: newCompany.Patents,
      project: newCompany.Project,
      comments: newCompany.Comments,
      answer: newCompany.Answer,
      answer_color: newCompany.Answer_Color,
      contact_log: newCompany.Log,
    })
      .then((response) => {
        //console.log(response.data);
        props.onFilter(filter);
        props.onsetAlertData({
          title: "Company",
          message: "Company " + newCompany.Name + " created correctly",
          type: "success",
        });
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response);
        // console.log(error.response.data);
        // console.log(error.response.data["non_field_errors"]);
        // console.log(Object.getOwnPropertyNames(error.response.data));
        let response = "";
        if (error.response.data[0]) response = error.response.data[0];

        if (error.response.data["non_field_errors"])
          response = error.response.data["non_field_errors"];

        props.onsetAlertData({
          title: "Company",
          message: "There was a problem creating the company",
          type: "danger",
        });
        if (error.response.status === 400)
          props.onsetAlertData((prevState) => {
            return {
              ...prevState,
              message: [...(prevState.message + ". " + response)],
            };
          });
      });
    toggle();
    props.onHandleVisible();
  };

  const searchHandler = (e) => {
    e.preventDefault();
    props.onFilter(filter);
  };
  //filter
  const [filter, setFilter] = useState({
    enteredName: "",
    enteredDescription: "",
    enteredEmployees: "",
    enteredPatents: "",
    enteredCountry: "",
    enteredProjects: [],
    enteredAnswered: [],
  });
  //filters states
  function onSelect(selectedList, selectedItem) {
    // console.log(selectedItem);
    // console.log(selectedItem["id"]);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredProjects: [...prevState.enteredProjects, selectedItem["id"]],
      };
    });
    // console.log(props.colors[0].answer_color)
  }

  function onRemove(selectedList, removedItem) {
    // console.log(removedItem);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredProjects: [
          ...prevState.enteredProjects.filter(
            (project) => project !== removedItem["id"]
          ),
        ],
      };
    });
  }

  function onSelectAnswered(selectedList, selectedItem) {
    // console.log(selectedItem);
    // console.log(selectedItem["id"]);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredAnswered: [...prevState.enteredAnswered, selectedItem["id"]],
      };
    });

   
  }

  function onRemoveAnswered(selectedList, removedItem) {
    // console.log(removedItem);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredAnswered: [
          ...prevState.enteredAnswered.filter(
            (answer) => answer !== removedItem["id"]
          ),
        ],
      };
    });
  }

  const changeNameHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredName: event.target.value };
    });
  };
  const changeDescriptionHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredDescription: event.target.value };
    });
  };
  const changeEmployeesHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredEmployees: event.target.value };
    });
  };
  const changePatentsHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredPatents: event.target.value };
    });
  };
  const changeCountryHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredCountry: event.target.value };
    });
  };
  //newcompany
  function onSelectNewCompanyProject(selectedList, selectedItem) {
    // console.log(selectedItem);
    // console.log(selectedItem["id"]);
    setNewCompany((prevState) => {
      return { ...prevState, Project: selectedItem["id"] };
    });
    if (selectedItem["id"] > 0) {
      setIsProjectSelected(true);
      setShowErrors(false);
    }
  }

  const changeNewNameHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Name: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsNameFilled(true);
      setShowErrors(false);
    } else {
      setIsNameFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewDescriptionHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Description: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsDescriptionFilled(true);
      setShowErrors(false);
    } else {
      setIsDescriptionFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewEmployeesHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Employees: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsEmployeesFilled(true);
      setShowErrors(false);
    } else {
      setIsEmployeesFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewPatentsHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Patents: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsPatentsFilled(true);
      setShowErrors(false);
    } else {
      setIsPatentsFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewCountryHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Country: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsCountryFilled(true);
      setShowErrors(false);
    } else {
      setIsCountryFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewLinkHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Link: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsLinkFilled(true);
      setShowErrors(false);
    } else {
      setIsLinkFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewAnswerHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Answer: event.target.value };
    });
    /*if (event.target.value.trim().length > 0) {
      setIsAnswerFilled(true);
      setShowErrors(false);
    } else {
      setIsAnswerFilled(false);
      setShowErrors(true);
    }*/
  };
  const changeNewCommentsHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Comments: event.target.value };
    });
    /*if (event.target.value.trim().length > 0) {
      setIsCommentsFilled(true);
      setShowErrors(false);
    } else {
      setIsCommentsFilled(false);
      setShowErrors(true);
    }*/
  };
  const changeNewLogHandler = (event) => {
    setNewCompany((prevState) => {
      return { ...prevState, Log: event.target.value };
    });
    /*if (event.target.value.trim().length > 0) {
      setIsLogFilled(true);
      setShowErrors(false);
    } else {
      setIsLogFilled(false);
      setShowErrors(true);
    }*/
  };
  function onSelectNewCompanyColor(e) {
    
    // console.log( e.target.value);
    setNewCompany((prevState) => {
      return { ...prevState, Answer_Color:  e.target.value };
    });
  
  }
  return (
    <Header>
      <Form>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="companyName">
                Name
              </label>
              <Input
                id="companyName"
                placeholder="Name"
                type="text"
                value={filter.enteredName}
                onChange={changeNameHandler}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="companyDescription"
              >
                Description
              </label>
              <Input
                id="companyDescription"
                placeholder="Description"
                type="text"
                value={filter.enteredDescription}
                onChange={changeDescriptionHandler}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="companyEmployees">
                Employees
              </label>
              <Input
                id="companyEmployees"
                placeholder="Employees"
                type="text"
                value={filter.enteredEmployees}
                onChange={changeEmployeesHandler}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="companyPatents">
                Patentes
              </label>
              <Input
                id="companyPatents"
                placeholder="Patents"
                type="text"
                value={filter.enteredPatents}
                onChange={changePatentsHandler}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="companyCountry">
                Country
              </label>
              <Input
                id="companyCountry"
                placeholder="Country"
                type="text"
                value={filter.enteredeCountry}
                onChange={changeCountryHandler}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="companyProject">
                Project
              </label>

              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={onRemove}
                onSearch={function noRefCheck() {}}
                onSelect={onSelect}
                options={props.projects}
                placeholder="Project"
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)
                    backgroundColor: "#fff",
                    borderRadius: "0.375rem",
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                    minHeight: "47px",
                  },
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="companyAnswer">
                Answered
              </label>

              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={onRemoveAnswered}
                onSearch={function noRefCheck() {}}
                onSelect={onSelectAnswered}
                options={[{name: 'YES', id: "YES"},{name: 'NO', id: "NO"}]}
                placeholder="Answer"
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)
                    backgroundColor: "#fff",
                    borderRadius: "0.375rem",
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                    minHeight: "47px",
                  },
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12" className="text-right">
            <Button
              className="btn-icon btn-3 btn-circle"
              color="primary"
              type="button"
              data-placement="top"
              id="tooltipadd"
              onClick={toggle}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
            </Button>
            <UncontrolledTooltip delay={0} placement="top" target="tooltipadd">
              Add New Company
            </UncontrolledTooltip>
            <AppModal
              toggle={toggle}
              modal={modal}
              title={"New Company"}
              handleSubmission={handleSubmission}
              action={"Add Company"}
            >
              <Row>
                <Col sm="4">
                  <label className="form-control-label">Project</label>
                  <Multiselect
                    displayValue="name"
                    onKeyPressFn={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={onSelectNewCompanyProject}
                    options={props.projects}
                    singleSelect={true}
                    placeholder="Project"
                    className={`bg-${
                      !isProjectSelected & showErrors ? "danger" : "secundary"
                    }`}
                    style={{
                      multiselectContainer: {
                        // To change css for multiselect (Width,height,etc..)

                        borderRadius: "0.375rem",
                      },
                    }}
                  />
                </Col>
                <Col sm="4">
                  <label className="form-control-label" htmlFor="companyName">
                    Name
                  </label>
                  <Input
                    type="text"
                    name="companyName"
                    onChange={changeNewNameHandler}
                    className={!isNameFilled & showErrors ? "is-invalid" : ""}
                  />
                </Col>
                <Col sm="4">
                  <label
                    className="form-control-label"
                    htmlFor="companyCountry"
                  >
                    Country
                  </label>
                  <Input
                    type="text"
                    name="companyCountry"
                    onChange={changeNewCountryHandler}
                    className={
                      !isCountryFilled & showErrors ? "is-invalid" : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label className="form-control-label" htmlFor="companyLink">
                    Link
                  </label>
                  <Input
                    type="text"
                    name="companyLink"
                    onChange={changeNewLinkHandler}
                    className={!isLinkFilled & showErrors ? "is-invalid" : ""}
                  />
                </Col>
                <Col sm="4">
                  <label
                    className="form-control-label"
                    htmlFor="companyEmployee"
                  >
                    Employees
                  </label>
                  <Input
                    type="text"
                    name="companyEmployee"
                    onChange={changeNewEmployeesHandler}
                    className={
                      !isEmployeesFilled & showErrors ? "is-invalid" : ""
                    }
                  />
                </Col>
                <Col sm="4">
                  <label
                    className="form-control-label"
                    htmlFor="companyPatents"
                  >
                    Patents
                  </label>
                  <Input
                    type="text"
                    name="companyPatents"
                    onChange={changeNewPatentsHandler}
                    className={
                      !isPatentsFilled & showErrors ? "is-invalid" : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <label
                    className="form-control-label"
                    htmlFor="companyDescription"
                  >
                    Description
                  </label>
                  <Input
                    type="textarea"
                    name="companyDescription"
                    rows="3"
                    onChange={changeNewDescriptionHandler}
                    className={
                      !isDescriptionFilled & showErrors ? "is-invalid" : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="9">
                  <label className="form-control-label" htmlFor="companyAnswer">
                    Answer
                  </label>
                  <Input
                    type="text"
                    name="companyAnswer"
                    onChange={changeNewAnswerHandler}
                    //className={
                    //  !isAnswerFilled & showErrors ? "is-invalid" : ""
                    //}
                  />
                </Col>
                <Col sm="3">
                  <label className="form-control-label" htmlFor="companyColor">
                    Color
                  </label>
                  <Input
                      type={"select"}
                      
                      //value={selectedOption}
                      onChange={onSelectNewCompanyColor.bind(this)}
                      style={{backgroundColor: `#${newCompany.Answer_Color}` }}
                  >
                    <option value="" hidden ></option>

                    {props.colors.map((color) => (
                      
                      <option
                        key={color.answer_color}
                        value={color.answer_color}
                        style={{color:`#${color.answer_color}`}}
                      >{ntc.name(`#${color.answer_color}`)[1]}</option>
                  ))}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="companyComments"
                  >
                    Comments
                  </label>
                  <Input
                    type="textarea"
                    name="companyComments"
                    rows="3"
                    onChange={changeNewCommentsHandler}
                    //className={
                    //   !isCommentsFilled & showErrors ? "is-invalid" : ""
                    //}
                  />
                </Col>

                <Col sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="companyContactLog"
                  >
                    Contact Log
                  </label>
                  <Input
                    type="textarea"
                    name="companyContactLog"
                    rows="3"
                    onChange={changeNewLogHandler}
                    //className={
                    //   !isLogFilled & showErrors ? "is-invalid" : ""
                    //}
                  />
                </Col>
              </Row>
            </AppModal>
            <Button
              className="btn-icon btn-3"
              color="primary"
              type="button"
              onClick={searchHandler}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-zoom-split-in" />
              </span>
              <span className="btn-inner--text">Search</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Header>
  );
};

export default CompanySearch;
