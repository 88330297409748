import React, { useState } from "react";

// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "../Headers/Header";
import AppModal from "../Commons/AppModal";
import Multiselect from "multiselect-react-dropdown";
import Ws from "../Axios/Axios";
import { getToken } from "../../Utils/Common";


const ContactSearch = (props) => {
  const [filter, setFilter] = useState({
    enteredName: "",
    enteredPosition: "",
    enteredEmail: "",
    enteredPhone: "",
    enteredCompany: "",
  });

  const [newContact, setNewContact] = useState({
    Name: "",
    Company: [],
    Phone: "",
    Position: "",
    Email: "",
    Linkedin: "",
  });
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [isNameFilled, setIsNameFilled] = useState(false);
  const [isPositionFilled, setIsPositionFilled] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [isLinkedinFilled, setIsLinkedinFilled] = useState(false);
  const [isPhoneFilled, setIsPhoneFilled] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  
  //modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [token, setToken] = useState(getToken());
  //filterhandlers
  const searchHandler = (e) => {
    e.preventDefault();
    props.onFilter(filter);
  };

  const changeNameHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredName: event.target.value };
    });
  };
  const changePositionHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredPosition: event.target.value };
    });
  };
  const changeEmailHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredEmail: event.target.value };
    });
  };
  const changePhoneHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredPhone: event.target.value };
    });
  };
  function onSelect(selectedList, selectedItem) {
    // console.log(selectedItem);
    // console.log(selectedItem["id"]);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredCompany: [...prevState.enteredCompany, selectedItem["id"]],
      };
    });
  }

  function onRemove(selectedList, removedItem) {
    // console.log(removedItem);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredCompany: [
          ...prevState.enteredCompany.filter(
            (company) => company !== removedItem["id"]
          ),
        ],
      };
    });
  }
  //newCompany Handler
  function onSelectNewCompanyContact(selectedList, selectedItem) {
    // console.log(selectedItem);
    // console.log(selectedItem["id"]);
    setNewContact((prevState) => {
      return { ...prevState, Company: selectedItem["id"] };
    });
    if (selectedItem["id"] > 0) {
      setIsCompanySelected(true);
      setShowErrors(false);
    }
  }

  const changeNewNameHandler = (event) => {
    setNewContact((prevState) => {
      return { ...prevState, Name: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsNameFilled(true);
      setShowErrors(false);
    } else {
      setIsNameFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewPhoneHandler = (event) => {
    setNewContact((prevState) => {
      return { ...prevState, Phone: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsPhoneFilled(true);
      setShowErrors(false);
    } else {
      setIsPhoneFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewPositionHandler = (event) => {
    setNewContact((prevState) => {
      return { ...prevState, Position: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsPositionFilled(true);
      setShowErrors(false);
    } else {
      setIsPositionFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewEmailHandler = (event) => {
    setNewContact((prevState) => {
      return { ...prevState, Email: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsEmailFilled(true);
      setShowErrors(false);
    } else {
      setIsEmailFilled(false);
      setShowErrors(true);
    }
  };
  const changeNewLinkedinHandler = (event) => {
    setNewContact((prevState) => {
      return { ...prevState, Linkedin: event.target.value };
    });
    if (event.target.value.trim().length > 0) {
      setIsLinkedinFilled(true);
      setShowErrors(false);
    } else {
      setIsLinkedinFilled(false);
      setShowErrors(true);
    }
  };


  const handleSubmission = () => {
    if (
      !isCompanySelected ||
      !isNameFilled ||
      !isPositionFilled ||
      !isEmailFilled ||
      !isLinkedinFilled ||
      !isPhoneFilled 
    ) {
      setShowErrors(true);
      return;
    }
    const Ws1 = Ws(true, token);
    Ws1.post("contact/", {
      name: newContact.Name,
      position: newContact.Position,
      email: newContact.Email,
      linkedin: newContact.Linkedin,
      phone: newContact.Phone,
      company: newContact.Company,
    })
      .then((response) => {
        // console.log(response.data);
        props.onFilter(filter);
        props.onsetAlertData({
          title: "Contact",
          message: "Contact " + newContact.Name + " created correctly",
          type: "success",
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response);
        // console.log(error.response.data);
        // console.log(error.response.data['non_field_errors']);
        // console.log(Object.getOwnPropertyNames(error.response.data));
        let response =''
        if (error.response.data[0])
          response = error.response.data[0]
        
        if (error.response.data['non_field_errors'])
          response = error.response.data['non_field_errors']
        
        props.onsetAlertData({
          title: "Contact",
          message: "There was a problem creating the Contact",
          type: "danger",
        });
        if (error.response.status === 400)
          props.onsetAlertData((prevState) => {
            return {
              ...prevState,
              message: [...(prevState.message + ". " + response)],
            };
          });
      });
    toggle();
    props.onHandleVisible();
  };

  return (
    <Header>
      <Form>
      
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="contactName">
                Name
              </label>
              <Input
                id="contactName"
                placeholder="Name"
                type="text"
                value={filter.enteredName}
                onChange={changeNameHandler}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="contactPosition">
                Position
              </label>
              <Input
                id="contactPosition"
                placeholder="Position"
                type="text"
                value={filter.enteredPosition}
                onChange={changePositionHandler}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="contactEmail">
                Email
              </label>
              <Input
                id="contactEmail"
                placeholder="Email"
                type="text"
                value={filter.enteredEmail}
                onChange={changeEmailHandler}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="contactPhone">
                Phone
              </label>
              <Input
                id="contactPhone"
                placeholder="Phone"
                type="text"
                value={filter.enteredPhone}
                onChange={changePhoneHandler}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="contactCompany">
                Company
              </label>
              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={onRemove}
                onSearch={function noRefCheck() {}}
                onSelect={onSelect}
                options={props.companies}
                placeholder="Company"
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)
                    backgroundColor: "#fff",
                    borderRadius: "0.375rem",
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                    minHeight: "47px",
                  },
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12" className="text-right">
            <Button
              className="btn-icon btn-3 btn-circle"
              color="primary"
              type="button"
              data-placement="top"
              id="tooltipadd"
              onClick={toggle}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
            </Button>
            <UncontrolledTooltip delay={0} placement="top" target="tooltipadd">
              Add New Contact
            </UncontrolledTooltip>
            <AppModal
              toggle={toggle}
              modal={modal}
              title={"New Contact"}
              handleSubmission={handleSubmission}
              action={"Add Contact"}
            >
              <Row>
                <Col md="6">
                  <label className="form-control-label">Company</label>
                  <Multiselect
                    displayValue="name"
                    onKeyPressFn={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={onSelectNewCompanyContact}
                    options={props.companies}
                    singleSelect={true}
                    placeholder="Company"
                    className={`bg-${
                      !isCompanySelected & showErrors ? "danger" : "secundary"
                    }`}
                    style={{
                      multiselectContainer: {
                        // To change css for multiselect (Width,height,etc..)

                        borderRadius: "0.375rem",
                      },
                    }}
                  />
                </Col>
                <Col md="6">
                  <label className="form-control-label" htmlFor="contactName">
                    Name
                  </label>
                  <Input
                    type="text"
                    name="contactName"
                    onChange={changeNewNameHandler}
                    className={!isNameFilled & showErrors ? "is-invalid" : ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <label
                    className="form-control-label"
                    htmlFor="contactPosition"
                  >
                    Position
                  </label>
                  <Input
                    type="text"
                    name="contactPosition"
                    onChange={changeNewPositionHandler}
                    className={!isPositionFilled & showErrors ? "is-invalid" : ""}
                  />
                </Col>
                <Col md="6">
                  <label className="form-control-label" htmlFor="contactEmail">
                    Email
                  </label>
                  <Input
                    type="text"
                    name="contactEmail"
                    onChange={changeNewEmailHandler}
                    className={!isEmailFilled & showErrors ? "is-invalid" : ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <label
                    className="form-control-label"
                    htmlFor="contactLinkedin"
                  >
                    Linkedin
                  </label>
                  <Input
                    type="text"
                    name="contactLinkedin"
                    onChange={changeNewLinkedinHandler}
                    className={!isLinkedinFilled & showErrors ? "is-invalid" : ""}
                  />
                </Col>
                <Col md="6">
                  <label className="form-control-label" htmlFor="contactPhone">
                    Phone
                  </label>
                  <Input
                    type="text"
                    name="contactPhone"
                    onChange={changeNewPhoneHandler}
                    className={!isPhoneFilled & showErrors ? "is-invalid" : ""}
                  />
                </Col>
              </Row>
            </AppModal>
            <Button
              className="btn-icon btn-3"
              color="primary"
              type="button"
              onClick={searchHandler}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-zoom-split-in" />
              </span>
              <span className="btn-inner--text">Search</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Header>
  );
};

export default ContactSearch;
