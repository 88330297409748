import React from "react";
import {
   
    Button,
   Row,
   Col,
    UncontrolledTooltip,
  } from "reactstrap";


import { useState, useEffect } from "react";


const ActionButton = React.memo(props => {
  //const { row } = props;

  
  //console.log(props)
  const addSingleToList = () => {
    //console.log("onValidButtonClick:", row.productId);
    props.toggle();
    props.setID([props.data.id])

  };
  const deleteHandler = () => {
    //console.log("onInvalidButtonClick:", row.productId);
    //console.log(props.data.id)
    props.onDelete(props.data.id)
  };

 
  return (
    <>
     
    <Button className="btn-icon btn-1" id="share" color="info" type="button" size="sm" onClick={addSingleToList} >
      <span className="btn-icon-only">
        <i className="ni ni-fat-add"></i>
      </span>
    </Button>
    <Button className="btn-icon btn-1" id="delete" color="danger" type="button" size="sm" onClick={deleteHandler} >
      <span className="btn-icon-only">
        <i className="ni ni-fat-remove"></i>
      </span>
    </Button>
   
    </>
  );
});
export default ActionButton;
