import React, { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import "./modal.css";
// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const AppModal = (props) => {

  const handleSubmission = () => {
    props.handleSubmission()
  };
  return (
    <Modal isOpen={props.modal} toggle={props.toggle} dialogClassName="my-modal" >
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody>
        {props.children}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmission}>
          {props.action}
        </Button>{" "}
        <Button onClick={props.toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppModal;
