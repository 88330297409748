/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch, Redirect, Link } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "../Navbars/AuthNavbar.js";
import AuthFooter from "../Footers/AuthFooter.js";
import Login from '../../Login'

import authRoutes from "../../authRoutes";

import "../Commons/modal.css";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      
        return (
          <Link
            to={prop.path}
            key={key}
          />
        );
      
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar></AuthNavbar>
        <div className="header bg-gradient-secundary py-1 py-lg-5">
          <div className="mt--4 pb-7 container">
          
            <div className="header-body text-center mb-7">
            
              <Row className="justify-content-center">
                <Col lg="5" md="3"   className="div-absolute">
                <img className="d-max-none"
              alt="..."
              src={
                require("../../assets/img/brand/VIROMII_300x80.png").default
              }
            />
                </Col>
              </Row>
            
            </div>
            
          </div>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-2">
          <Row className="justify-content-center">
              {getRoutes(authRoutes)}
             
              {props.children}
             
              {/*<Redirect from="*" to="/auth/login" />*/}
          </Row>
        </Container>
      </div>
     
    </>
  );
};

export default Auth;
