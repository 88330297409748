import React, { useState, useEffect } from "react";

// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "../Headers/Header";
import Multiselect from "multiselect-react-dropdown";
import AppModal from "../Commons/AppModal";
import Ws from "../Axios/Axios";
import {
  getToken,
  getUserId,
} from "../../Utils/Common";



const AnswerSearch = (props) => {
  //modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  //
  
  const [token, setToken] = useState(getToken());
  const [filter, setFilter] = useState({
    enteredProjects: [],
    enteredCompanies: []
  });

  //const [newName, setNewName] = useState('');
  //const [isValid, setIsValid] = useState(true);

  const searchHandler = (e) => {
    e.preventDefault();
    props.onFilter(filter);
  };



  function onSelectProject(selectedList, selectedItem) {
    //console.log(selectedItem);
    //console.log(selectedItem["id"]);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredProjects: [...prevState.enteredProjects, selectedItem["id"]],
      };
    });
  }

  function onRemoveProject(selectedList, removedItem) {
    //console.log(removedItem);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredProjects: [
          ...prevState.enteredProjects.filter(
            (project) => project !== removedItem["id"]
          ),
        ],
      };
    });
  }
  function onSelectCompany(selectedList, selectedItem) {
    //console.log(selectedItem);
    //console.log(selectedItem["id"]);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredCompanies: [...prevState.enteredCompanies, selectedItem["id"]],
      };
    });
  }

  function onRemoveCompany(selectedList, removedItem) {
    //console.log(removedItem);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredCompanies: [
          ...prevState.enteredCompanies.filter(
            (project) => project !== removedItem["id"]
          ),
        ],
      };
    });
  }
  
  return (
    <Header className='pb-6'>
      <Form>
        <Row>
          <Col md="6">
          <FormGroup>
              <label className="form-control-label" htmlFor="companyProject">
                Project
              </label>

              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={onRemoveProject}
                onSearch={function noRefCheck() {}}
                onSelect={onSelectProject}
                options={props.projects}
                placeholder="Project"
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)
                    backgroundColor: "#fff",
                    borderRadius: "0.375rem",
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                    minHeight: "47px",
                  },
                }}
              />
            </FormGroup>
            
          </Col>
          <Col md="6">
          <FormGroup>
              <label className="form-control-label" htmlFor="companyProject">
                Company
              </label>

              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={onRemoveCompany}
                onSearch={function noRefCheck() {}}
                onSelect={onSelectCompany}
                options={props.companies}
                placeholder="Company"
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)
                    backgroundColor: "#fff",
                    borderRadius: "0.375rem",
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                    minHeight: "47px",
                  },
                }}
              />
            </FormGroup>
            </Col>
        </Row>

        <Row>
          <Col md="12" className="text-right">
                
            <Button
              className="btn-icon btn-3"
              color="primary"
              type="button"
              onClick={searchHandler}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-zoom-split-in" />
              </span>
              <span className="btn-inner--text">Search</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Header>
  );
};

export default AnswerSearch;
