import React from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components

import Paginator from "../Commons/Paginator";
import BaseTable1 from "../Commons/BaseTable";
import { Column } from "react-base-table";
import ActionButton from "./ActionButtons";

function ContactTable(props) {
  const deleteContactHandler = (contact_id) => {
    // console.log("En TAble");
    // console.log(contact_id);
    props.onDelete(contact_id);
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      resizable: true,
      sortable: true,
      frozen: Column.FrozenDirection.LEFT,
      flexShrink: 0,
      flexGrow: 3,
      cellRenderer: ({ cellData: name }) => (
        <th scope="row">
          <Media className="align-items-center">
            <Media>
              <span className="mb-0 text-sm">{name}</span>
            </Media>
          </Media>
        </th>
      ),
    },
    {
      key: "position",
      title: "Position",
      dataKey: "position",
      width: 200,
      align: Column.Alignment.CENTER,
      sortable: false,
      resizable: true,
      //cellRenderer: ({ cellData: score }) => <p >{score}</p>,
    },
    {
      key: "email",
      title: "Email",
      dataKey: "email",
      width: 200,
      align: Column.Alignment.CENTER,
      sortable: false,
      resizable: true,
      //cellRenderer: ({ cellData: score }) => <p >{score}</p>,
    },
    {
      key: "linkedin",
      title: "Linkedin",
      dataKey: "linkedin",
      width: 100,
      align: Column.Alignment.CENTER,
      sortable: false,
      resizable: true,
      //cellRenderer: ({ cellData: score }) => <p >{score}</p>,
    },
    {
      key: "phone",
      title: "Phone",
      dataKey: "phone",
      width: 100,
      align: Column.Alignment.CENTER,
      sortable: false,
      resizable: true,
      //cellRenderer: ({ cellData: score }) => <p >{score}</p>,
    },
    {
      key: "company_info",
      title: "Company",
      dataKey: "company_info",
      width: 150,
      align: Column.Alignment.CENTER,
      sortable: false,
      resizable: true,
      cellRenderer: ({ cellData: company }) => <span>{company["name"]}</span>,
    },
    {
      key: "action",
      width: 100,
      align: Column.Alignment.CENTER,
      frozen: Column.FrozenDirection.RIGHT,
      cellRenderer: ({ rowData }) => (
        <ActionButton data={rowData} onDelete={deleteContactHandler} />
      ),
    },
  ];

  return (
    <>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Contact table</h3>
              </CardHeader>

              <BaseTable1 columns={columns} data={props.contacts}></BaseTable1>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    pages={props.pages}
                    onUpdatePages={props.onUpdatePage}
                  ></Paginator>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ContactTable;
