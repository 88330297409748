/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch, Redirect, Link } from "react-router-dom";
// reactstrap components
import { Container, Button, UncontrolledCollapse } from "reactstrap";
// core components
import AdminNavbar from "../Navbars/AdminNavbar";
import AdminFooter from "../Footers/AdminFooter.js";
import Sidebar from "../Sidebar/Sidebar.js";
import PrivateRoute from "../../Utils/PrivateRoute";
import { useState } from "react";
import routes from "../../routes";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const menuContent = React.useRef(null);
  const location = useLocation();
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [sign, setSign] = useState("bold-left");

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return <Link to={prop.path} key={key} />;
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");

      // console.log(document.body.classList);

      document.getElementById("sidenav-main").removeAttribute("hidden");
      setSign("bold-left");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      // console.log(document.body.classList);
      document.getElementById("sidenav-main").setAttribute("hidden", true);
      setSign("bold-right");
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/project",
          imgSrc: require("../../assets/img/brand/VIROMII_300x80.png").default,
          imgAlt: "...",
          sizes: "(max-width: 3190px) 100vw, 3190px",
        }}
      />

      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav}></div>
      ) : null}

      <div className="main-content" ref={mainContent}>
        <Button
          className="btn-icon btn-1"
          color="info"
          type="button"
          size="sm"
          onClick={toggleSidenav}
        >
          <span className="btn-icon-only">
            <i className={`ni ni-${sign}`}></i>
          </span>
        </Button>
        {getRoutes(routes)}

        <Container fluid>
          {props.children}

          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
