import React from "react";
// reactstrap components
import { Alert } from "reactstrap";

const Alerts = (props)=> {

    //alert-defaul
    //primary
    //secondary
    //info
    //danger
    //warning

    
    return (
      <>
        <Alert isOpen={props.isOpen} color={props.type}>
          <strong>{props.title}</strong> {props.children}
        </Alert>
      </>
    );
  
}
export default Alerts;