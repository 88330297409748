import React, { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import Alert from "../Commons/Alert";

// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "../Headers/Header";
import AppModal from "../Commons/AppModal";
import Ws from "../Axios/Axios";
import { getToken } from "../../Utils/Common";

const ProjectSearch = (props) => {
  const [filter, setFilter] = useState({
    enteredName: "",
    enteredUser: [],
  });
  const [token, setToken] = useState(getToken());

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isNameFilled, setIsNameFilled] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const [projectName, setProjectName] = useState();
  const [projectUser, setProjectUser] = useState();
  //new modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // new propject

  const onSelectNewProjectFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    setShowErrors(false);
  };

  const changeNewProjectNameHandler = (event) => {
    setProjectName(event.target.value);
    if (event.target.value.trim().length > 0) {
      setIsNameFilled(true);
      setShowErrors(false);
    } else {
      setIsNameFilled(false);
      setShowErrors(true);
    }
  };

  function onSelectNewProjectUser(selectedList, selectedItem) {
    setProjectUser(selectedItem["id"]);
    // console.log(selectedItem["id"]);
    if (selectedItem["id"] > 0) {
      setIsUserSelected(true);
      setShowErrors(false);
    }
  }

  const handleSubmission = () => {
    const formData = new FormData();
    formData.append("file_uploaded", selectedFile);
    formData.append("project_name", projectName);
    formData.append("user", projectUser);

    if (!isFilePicked || !isNameFilled || !isUserSelected) {
      setShowErrors(true);
      return;
    }
    const Ws1 = Ws(true, token, "multipart/form-data");
    Ws1.post("upload/", formData)
      .then((response) => {
        // console.log(response.data);
        props.onsetAlertData({
          title: "Project Upload",
          message: "Project Uploaded Correctly",
          type: "success",
        });
      })
      .catch((error) => {
        props.onsetAlertData({
          title: "Project Upload",
          message: "There was a problem uploading the project",
          type: "danger",
        });
        if (error.response.status === 400)
          props.onsetAlertData((prevState) => {
            return {
              ...prevState,
              message: prevState.message + ". " + error.response.data[0],
            };
          });
      });
    toggle();
    props.onHandleVisible();
  };
  //filters
  const onSearchHandler = (e) => {
    e.preventDefault();
    // console.log(filter);
    props.onUpdatePage({
      previousPage: null,
      currentPage: 1,
      nextPage: null,
    });
    props.onFilter(filter);
  };

  const changeFilterNameHandler = (event) => {
    setFilter((prevState) => {
      return { ...prevState, enteredName: event.target.value };
    });
  };
  function onSelect(selectedList, selectedItem) {
    // console.log(selectedItem);
    // console.log(selectedItem["id"]);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredUser: [...prevState.enteredUser, selectedItem["id"]],
      };
    });
  }

  function onRemove(selectedList, removedItem) {
    // console.log(removedItem);
    setFilter((prevState) => {
      return {
        ...prevState,
        enteredUser: [
          ...prevState.enteredUser.filter((user) => user !== removedItem["id"]),
        ],
      };
    });
  }

  return (
    <Header>
      <Form>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="projectName">
                Name
              </label>
              <Input
                id="projectName"
                placeholder="Name"
                type="text"
                value={filter.enteredName}
                onChange={changeFilterNameHandler}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="projectUser">
                User
              </label>
              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={onRemove}
                onSearch={function noRefCheck() {}}
                onSelect={onSelect}
                options={props.users}
                placeholder="User"
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)
                    backgroundColor: "#fff",
                    borderRadius: "0.375rem",
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                    minHeight: "47px",
                  },
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12" className="text-right">
            <Button
              className="btn-icon btn-3 btn-circle"
              color="primary"
              type="button"
              data-placement="top"
              id="tooltipadd"
              onClick={toggle}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
            </Button>
            <UncontrolledTooltip delay={0} placement="top" target="tooltipadd">
              Add New Project
            </UncontrolledTooltip>
            <AppModal
              toggle={toggle}
              modal={modal}
              title={"New Project"}
              handleSubmission={handleSubmission}
              action={"Upload"}
            >
              <label className="form-control-label" htmlFor="projectName">
                Name
              </label>
              <Input
                type="text"
                name="projectName"
                className={!isNameFilled & showErrors ? "is-invalid" : ""}
                onChange={changeNewProjectNameHandler}
              />
              <label className="form-control-label" htmlFor="projectUser">
                User
              </label>
              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={onSelectNewProjectUser}
                options={props.users}
                singleSelect={true}
                placeholder="User"
                className={`bg-${
                  !isUserSelected & showErrors ? "danger" : "secundary"
                }`}
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)

                    borderRadius: "0.375rem",
                  },
                }}
              />
              <div className="custom-file">
                <label className="form-control-label" htmlFor="projectFile">
                  File
                </label>

                <Input
                  type="file"
                  name="projectFile"
                  onChange={onSelectNewProjectFileSelect}
                  className={`form-control bg-${
                    !isFilePicked & showErrors ? "danger" : "secundary"
                  }`}
                />
              </div>
            </AppModal>

            <Button
              className="btn-icon btn-3"
              color="primary"
              type="button"
              onClick={onSearchHandler}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-zoom-split-in" />
              </span>
              <span className="btn-inner--text">Search</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Header>
  );
};

export default ProjectSearch;
