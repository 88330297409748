import React from "react";
import { BrowserRouter, Switch, Route, NavLink } from "react-router-dom";
import Ws from "./components/Axios/Axios";

import Login from "./Login";
import Dashboard from "./Dashboard";
import Home from "./Home";
import Project from "./components/Project/Project";
import Company from "./components/Company/Company";
import Contact from "./components/Contact/Contact";
import Answer from "./components/Answer/Answer";
import List from "./components/List/List";
import User from "./components/User/NewUser";
import ChangePassword from "./components/User/ChangePassword";
import RessetPassword from "./components/User/ResetPassword";
import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import PrivateStaffRoute from "./Utils/PrivateStaffRoute";
import Admin from "./components/layouts/Admin"
import Auth from "./components/layouts/Auth"
import {
  getToken,
  getUser,
  removeUserSession,
  setUserSession,
  getUserId,
  getUserStaff
} from "./Utils/Common";
import { useState, useEffect } from "react";

function App() {
  const [authLoading, setAuthLoading] = useState(true);
  const [isStaff, setisStaff] = useState(false);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    const user = getUser();
    const userid = getUserId();
    const userstaff = getUserStaff();
    

    const Ws1 = Ws(true, token);
    Ws1.get(`profile/?search=${user}&isactive=true`)
      .then((response) => {
        setUserSession(token, user,userid,userstaff);
        setisStaff(userstaff);
        setAuthLoading(false);
      })
      .catch((error) => {
        removeUserSession();
        setAuthLoading(false);
      });
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <div className="content">
            <Switch>

            <PublicRoute path="/login" component={Login} exact></PublicRoute>
            <PublicRoute path="/reset_password" component={RessetPassword} exact></PublicRoute>
            <PublicRoute path="/change_password" component={ChangePassword} exact></PublicRoute>

        
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                
                <PrivateRoute exact path="/project" component={Project} />
                <PrivateRoute exact path="/company" component={Company} />
                <PrivateRoute exact path="/contact" component={Contact} />
                <PrivateRoute exact path="/list" component={List} />
                <PrivateRoute exact path="/answer" component={Answer} />
                <PrivateStaffRoute exact path="/user" component={User} />
             
              {/*Route exact path="/" component={Home} />
             */}
            
              
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
