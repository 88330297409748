
import React, { useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner,
  } from "reactstrap";
  import Alert from "../Commons/Alert";
  import {
    getToken
  } from "../../Utils/Common";
  import Ws from "../Axios/Axios";
  import { useHistory } from "react-router-dom";
  
  const ResetPassword = () => {
    const [newUser, setNewUser] = useState({
        Email: ""
    });
    const [token, setToken] = useState(getToken());
    const [isEmailFilled, setIsEmailFilled] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const history = useHistory();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [alertData, setAlertData] = useState({
        title: "",
        message: "",
        type: "",
    });

  
      const changeNewEmailHandler = (event) => {
        setNewUser((prevState) => {
          return { ...prevState, Email: event.target.value };
        });
        if (event.target.value.trim().length > 0) {
            setIsEmailFilled(true);
          setShowErrors(false);
        } else {
            
            setIsEmailFilled(false);
          setShowErrors(true);
        }
      };
    
      const handleSubmission = () => {
        if (
          !isEmailFilled 
        ) {
          setShowErrors(true);
          return;
        }
        setLoading(true);
        const Ws1 = Ws(false, token);
        Ws1.post("password_reset/", {
         
          email: newUser.Email
         
        })
          .then((response) => {
            setLoading(false);
            // console.log(response.data);
            setNewUser({
                Email: ""
            });
            setAlertData({
              title: "Reset Password",
              message: "We have sent an email to reset your password. Please check your inbox",
              type: "success",
            });
            
            
          })
          .catch((error) => {
            setLoading(false);
            // console.log(error);
            // console.log(error.response);
            // console.log(error.response.data);
            // console.log(error.response.data["non_field_errors"]);
            // console.log(Object.getOwnPropertyNames(error.response.data));
            let response = "";
            if (error.response.data[0]) response = error.response.data[0];
            
            if (error.response.data["non_field_errors"])
              response = error.response.data["non_field_errors"];
              
            if (error.response.data["email"])
              response = error.response.data["email"];
    
              setAlertData({
              title: "Reset Password",
              message: "There was a problem trying to reset your password",
              type: "danger",
            });
            if (error.response.status === 400)
            setAlertData((prevState) => {
                return {
                  ...prevState,
                  message: [...(prevState.message + ". " + response)],
                };
              });
          });
        
          handleVisible(true);
          
      };
      const handleVisible = (redirect=false) => {
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
          if (redirect)
            history.push("/login");
        }, 10000);
      };
    return (
      <>
      <Alert
        title={alertData.title}
        type={alertData.type}
        isOpen={visibleAlert}
      >
        {alertData.message}
      </Alert>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2">
              <p>Reset Password</p>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      onChange={changeNewEmailHandler}
                      value={newUser.Email}
                      className={!isEmailFilled & showErrors ? "is-invalid" : ""}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={handleSubmission} value={loading ? "Loading..." : "Reset Password"} disabled={loading}>
                    Reset Password
                  </Button>
                  <br/>
                {
                  loading? (<Spinner  size='sm'/>) : null
                }
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="/login"
            >
              <small>Go Back</small>
            </a>
          </Col>
        </Row>
        </Col>
      </>
    );
  };
  
  export default ResetPassword;
  