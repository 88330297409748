import axios from "axios";
import React from "react";

const Axios = function (
  mustAutenticate,
  token,
  type = "application/json;charset=utf-8",
  responseType = "json"
) {
  //axios.defaults.baseURL = "http://localhost:8000/api/";
  axios.defaults.baseURL = "https://api.viromii.com/api/";
  axios.defaults.headers.common["Content-Type"] = type;
  axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["Access-Control-Allow-Methods"] = "GET, POST, PATCH, PUT, DELETE, OPTIONS";
  axios.defaults.responseType = responseType;
  //console.log(token);
  if (mustAutenticate)
    axios.defaults.headers.common["Authorization"] = "Token " + token;

  return axios;
};

export default Axios;
