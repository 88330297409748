import React from "react";
import BaseTable, {
  Column,
  normalizeColumns,
  callOrReturn,
} from "react-base-table";
import styled from "styled-components";

const noop = () => {};

const StyledTable = styled(BaseTable)`
  .row-selected {
    background-color: #e3e3e3;
  }
`;

const rowRenderer = ({ rowData, rowIndex, cells, columns }) => {
  let style = {};
  let i = 0;
  if (cells[6]) {
    i = 6;
  }
  const cell = cells[i];
  if (rowData.answer_color) {
    style = {
      ...cell.props.style,
      backgroundColor: `#${rowData.answer_color}`,
    };
  } else {
    {
      style = {
        ...cell.props.style,
      };
    }
  }

  cells[i] = React.cloneElement(cell, { style });

  return cells;
};

class SelectionCell extends React.PureComponent {
  _handleChange = (e) => {
    const { rowData, rowIndex, column } = this.props;
    const { onChange } = column;

    onChange({ selected: e.target.checked, rowData, rowIndex });
  };

  render() {
    const { rowData, column } = this.props;
    const { selectedRowKeys, rowKey } = column;
    const checked = selectedRowKeys.includes(rowData[rowKey]);

    return (
      <input type="checkbox" checked={checked} onChange={this._handleChange} />
    );
  }
}
class SelectionAll extends React.PureComponent {
  handleChange = (e) => {
    const { container, column } = this.props;
    const all = container._data;
    const { onChangeAll, selectedRowKeys } = column;

    for (let i = 0; i < all.length; i++) {
      onChangeAll({
        selected: e.target.checked,
        rowData: all[i],
        rowIndex: i,
        selectedRowKeys,
      });
    }
  };

  render() {
    return <input type="checkbox" onChange={this.handleChange} />;
  }
}
export default class SelectableTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      selectedRowKeys,
      defaultSelectedRowKeys,
      expandedRowKeys,
      defaultExpandedRowKeys,
    } = props;
    this.state = {
      selectedRowKeys:
        (selectedRowKeys !== undefined
          ? selectedRowKeys
          : defaultSelectedRowKeys) || [],
      expandedRowKeys:
        (expandedRowKeys !== undefined
          ? expandedRowKeys
          : defaultExpandedRowKeys) || [],
    };
  }

  /**
   * Set `selectedRowKeys` manually.
   * This method is available only if `selectedRowKeys` is uncontrolled.
   *
   * @param {array} selectedRowKeys
   */
  setSelectedRowKeys(selectedRowKeys) {
    // if `selectedRowKeys` is controlled
    if (this.props.selectedRowKeys !== undefined) return;

    // this.setState({
    //   selectedRowKeys: cloneArray(selectedRowKeys),
    // });
  }

  /**
   * See BaseTable#setExpandedRowKeys
   */
  setExpandedRowKeys(expandedRowKeys) {
    // if `expandedRowKeys` is controlled
    if (this.props.expandedRowKeys !== undefined) return;

    // this.setState({
    //   expandedRowKeys: cloneArray(expandedRowKeys),
    // });
  }

  /* some other custom methods and proxy methods */

  /**
   * Remove rowKeys from inner state  manually, it's useful to purge dirty state after rows removed.
   * This method is available only if `selectedRowKeys` or `expandedRowKeys` is uncontrolled.
   *
   * @param {array} rowKeys
   */
  removeRowKeysFromState(rowKeys) {
    if (!Array.isArray(rowKeys)) return;

    const state = {};
    if (
      this.props.selectedRowKeys === undefined &&
      this.state.selectedRowKeys.length > 0
    ) {
      state.selectedRowKeys = this.state.selectedRowKeys.filter(
        (key) => !rowKeys.includes(key)
      );
    }
    if (
      this.props.expandedRowKeys === undefined &&
      this.state.expandedRowKeys.length > 0
    ) {
      state.expandedRowKeys = this.state.expandedRowKeys.filter(
        (key) => !rowKeys.includes(key)
      );
    }
    if (state.selectedRowKeys || state.expandedRowKeys) {
      this.setState(state);
    }
  }
  _handleSelectAll = ({ selected, rowData, rowIndex, selectedRowKeys }) => {
    const key = rowData[this.props.rowKey];

    //console.log(selectedRowKeys);
    if (selected) {
      if (!selectedRowKeys.includes(key)) {
        selectedRowKeys.push(key);

        this.setState([...selectedRowKeys]);
      }
    } else {
      const index = selectedRowKeys.indexOf(key);
      if (index > -1) {
        selectedRowKeys.splice(index, 1);

        this.setState(selectedRowKeys);
      }
    }

    if (this.props.selectedRowKeys === undefined) {
      this.setState(selectedRowKeys);
    }

    this.props.onRowSelect({ selected, rowData, rowIndex });
    this.props.onSelectedRowsChange(selectedRowKeys);
  };
  _handleSelectChange = ({ selected, rowData, rowIndex }) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    const key = rowData[this.props.rowKey];
    //console.log(selectedRowKeys);
    if (selected) {
      if (!selectedRowKeys.includes(key)) selectedRowKeys.push(key);
    } else {
      const index = selectedRowKeys.indexOf(key);
      if (index > -1) {
        selectedRowKeys.splice(index, 1);
      }
    }

    // if `selectedRowKeys` is uncontrolled, update internal state
    if (this.props.selectedRowKeys === undefined) {
      this.setState({ selectedRowKeys });
    }
    this.props.onRowSelect({ selected, rowData, rowIndex });
    this.props.onSelectedRowsChange(selectedRowKeys);
  };

  _rowClassName = ({ rowData, rowIndex }) => {
    const { rowClassName, rowKey } = this.props;
    const { selectedRowKeys } = this.state;

    const rowClass = rowClassName
      ? callOrReturn(rowClassName, { rowData, rowIndex })
      : "";
    const key = rowData[rowKey];

    return [rowClass, selectedRowKeys.includes(key) && "row-selected"]
      .filter(Boolean)
      .concat(" ");
  };

  render() {
    const { columns, children, selectable, selectionColumnProps, ...rest } =
      this.props;
    const { selectedRowKeys } = this.state;

    // you'd better memoize this operation
    let _columns = columns || normalizeColumns(children);
    if (selectable) {
      const selectionColumn = {
        width: 40,
        flexShrink: 0,
        resizable: false,
        frozen: Column.FrozenDirection.LEFT,
        headerRenderer: SelectionAll,
        cellRenderer: SelectionCell,
        ...selectionColumnProps,
        key: "__selection__",
        rowKey: this.props.rowKey,
        selectedRowKeys: selectedRowKeys,
        onChange: this._handleSelectChange,
        onChangeAll: this._handleSelectAll,
      };
      _columns = [selectionColumn, ..._columns];
    }

    return (
      <StyledTable
        {...rest}
        columns={_columns}
        rowClassName={this._rowClassName}
        rowRenderer={rowRenderer}
      />
    );
  }
}

SelectableTable.defaultProps = {
  ...BaseTable.defaultProps,
  onRowSelect: noop,
  onSelectedRowsChange: noop,
};
