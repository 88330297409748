import React, { useState } from "react";
//import ProjectFilter from "./Project/ProjectFilter"
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import AppModal from "../Commons/AppModal";
import Multiselect from "multiselect-react-dropdown";
import Ws from "../Axios/Axios";
import { getToken } from "../../Utils/Common";
import * as ReactBootstrap from "react-bootstrap";
import ListCompany from "./ListCompany"

function ListItem(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [newUser, setNewUser] = useState();

  const [token, setToken] = useState(getToken());

  function onSelectNewListUser(selectedList, selectedItem) {
    // console.log(selectedItem);
    // console.log(selectedItem["id"]);
    setNewUser(selectedItem["id"]);
  }

  const deleteHandler = () => {
    // console.log(props.id);
    props.onDelete(props.id);
  };

  const handleDownload = () => {
    const Ws1 = Ws(true, token, "blob", "blob");
    Ws1.post("downloadlist/", {
      list: props.id,
    })
      .then((response) => {
        // console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  const handleShareList = () => {
    // console.log(props.id);
    toggle();
    props.onShare(props.id, newUser);
  };

  const deleteCompanyHandler = (company) => {
    // console.log(props.id);
    // console.log(company);
    props.onCompanyDelete(company,props.id);
  };
 
  
  return (
    <>
      <ReactBootstrap.Card>
        <ReactBootstrap.Card.Header>
          <ReactBootstrap.Accordion.Toggle
            as={ReactBootstrap.Button}
            variant="link"
            eventKey={props.id}
          >
            {props.name}
          </ReactBootstrap.Accordion.Toggle>
        </ReactBootstrap.Card.Header>
        <ReactBootstrap.Accordion.Collapse eventKey={props.id}>
          <ReactBootstrap.Card.Body>
            <Button
              className="my-1"
              color="info"
              onClick={handleDownload}
              size="sm"
            >
              Download
            </Button>
            <Button className="my-1" color="info" onClick={toggle} size="sm">
              Share
            </Button>
            <Button
              className="my-1"
              color="info"
              onClick={deleteHandler}
              size="sm"
            >
              Delete
            </Button>
            <Table>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {props.companies.map((company) => (
                  <ListCompany
                  id={company.id}
                  key={company.id}
                  name={company.name}
                  onCompanyDelete={deleteCompanyHandler}
                ></ListCompany>
                ))}
              </tbody>
            </Table>
          </ReactBootstrap.Card.Body>
        </ReactBootstrap.Accordion.Collapse>
      </ReactBootstrap.Card>
      <AppModal
        toggle={toggle}
        modal={modal}
        title={"Share List"}
        handleSubmission={handleShareList}
        action={"Share List"}
      >
        <Row>
          <Col md="12">
            <label className="form-control-label">User</label>
            <Multiselect
              displayValue="name"
              onKeyPressFn={function noRefCheck() {}}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectNewListUser}
              options={props.users}
              singleSelect={true}
              placeholder="User"
              style={{
                multiselectContainer: {
                  // To change css for multiselect (Width,height,etc..)

                  borderRadius: "0.375rem",
                },
              }}
            />
          </Col>
        </Row>
      </AppModal>
    </>
  );
}

export default ListItem;
