import Login from "./Login";
import ChangePassword from "./components/User/ChangePassword";
import ResetPassword from "./components/User/ResetPassword";



var authRoutes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-tv-2 text-primary",
    component: Login,
    layout: "",
  },
  {
    path: "/change_password",
    name: "Change Password",
    icon: "ni ni-tv-2 text-primary",
    component: ChangePassword,
    layout: "",
  },
  {
    path: "/reset_password",
    name: "Reset Password",
    icon: "ni ni-tv-2 text-primary",
    component: ResetPassword,
    layout: "",
  },
  /*{
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },*/
];
export default authRoutes;
