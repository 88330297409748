import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken, getUserStaff } from "./Common";
import Admin from "../components/layouts/Admin";

// handle the private routes
function PrivateStaffRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? (
       
          getUserStaff() ? (
            <Admin><Component {...props} /> </Admin>
          ) : (
            <Admin><p>You are not allowed to see this page</p> </Admin>
          )
         
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateStaffRoute;
