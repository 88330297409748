import React from "react";
import { Button, Row, Col, UncontrolledTooltip } from "reactstrap";

import { useState, useEffect } from "react";
import Ws from "../Axios/Axios";
import { getToken } from "../../Utils/Common";
const ActionButton = React.memo((props) => {
  //const { row } = props;

  const deleteHandler = () => {
    //console.log("onInvalidButtonClick:", row.productId);
    // console.log(props.data.id);
    props.onDelete(props.data.id);
  };

  const [token, setToken] = useState(getToken());
  // console.log(props)
  const handleDownload = () => {
    const Ws1 = Ws(true, token, "blob", "blob");
    Ws1.post("downloadproject/", {
      project: props.data.id,
    })
      .then((response) => {
        // console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  return (
    <>
      <Button
        className="btn-icon btn-1"
        id="share"
        color="info"
        type="button"
        size="sm"
        onClick={handleDownload}
      >
        <span className="btn-icon-only">
          <i className="ni ni-cloud-download-95"></i>
        </span>
      </Button>
      <Button
        className="btn-icon btn-1"
        id="delete"
        color="danger"
        type="button"
        size="sm"
        onClick={deleteHandler}
      >
        <span className="btn-icon-only">
          <i className="ni ni-fat-remove"></i>
        </span>
      </Button>
     
    </>
  );
});
export default ActionButton;
