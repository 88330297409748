import React from "react";
//import ProjectFilter from "./Project/ProjectFilter"
import CompanyTable from "./CompanyTable";
import CompanySearch from "./CompanySearch";
import Ws from "../Axios/Axios";
import { useState, useEffect } from "react";
import {
  getToken,
  getUser,
  removeUserSession,
  setUserSession,
} from "../../Utils/Common";
import confirm from "reactstrap-confirm";
import Alert from "../Commons/Alert";


function Company() {
  const [authLoading, setAuthLoading] = useState(true);
  const [companies, setCompanies] = useState([{}]);
  const [filters, setFilters] = useState([{}]);
  const [projects, setProjects] = useState([{}]);
  const [colors, setColors] = useState([{}]);

  const [token, setToken] = useState(getToken());
  const [visibleAlert, setAlertVisible] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [alertData, setAlertData] = useState({
    title: "",
    message: "",
    type: "",
  });

  const [pages, setPages] = useState({
    previousPage: null,
    currentPage: 1,
    nextPage: null
  });

  if (!token) {
    return;
  }

  useEffect(() => {
    const Ws1 = Ws(true, token);
    Ws1.get(`project/?page=get_all`)
      .then((response) => {
        //console.log(response.data);
        setProjects(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const Ws1 = Ws(true, token);
    Ws1.get(`color/`)
      .then((response) => {
        //console.log(response.data);
        setColors(response.data);
      })
      .catch((error) => {});
  }, []);

  
  useEffect(() => {
    const user = getUser();
    //console.log("prueba llamada");
    //console.log(filters);
    const Ws1 = Ws(true, token);

    let params = "";
    if (
      !(Object.keys(filters).length === 0 && filters.constructor === Object)
    ) {
      if (
        typeof filters.enteredCountry != "undefined" &&
        filters.enteredCountry != ""
      ) {
        params = params.includes("?")
          ? params + "&country=" + filters.enteredCountry
          : params + "?country=" + filters.enteredCountry;
      }

      if (
        typeof filters.enteredDescription != "undefined" &&
        filters.enteredDescription != ""
      ) {
        params = params.includes("?")
          ? params + "&description=" + filters.enteredDescription
          : params + "?description=" + filters.enteredDescription;
      }
      if (
        typeof filters.enteredEmployees != "undefined" &&
        filters.enteredEmployees != ""
      ) {
        params = params.includes("?")
          ? params + "&employees=" + filters.enteredEmployees
          : params + "?employees=" + filters.enteredEmployees;
      }
      if (
        typeof filters.enteredPatents != "undefined" &&
        filters.enteredPatents != ""
      ) {
        params = params.includes("?")
          ? params + "&patents=" + filters.enteredPatents
          : params + "?patents=" + filters.enteredPatents;
      }
      if (
        typeof filters.enteredName != "undefined" &&
        filters.enteredName != ""
      ) {
        params = params.includes("?")
          ? params + "&name=" + filters.enteredName
          : params + "?name=" + filters.enteredName;
      }
      if (
        typeof filters.enteredProjects != "undefined" &&
        filters.enteredProjects != ""
      ) {
        params = params.includes("?")
          ? params + "&project=" + filters.enteredProjects
          : params + "?project=" + filters.enteredProjects;
      }

      if (
        typeof filters.enteredAnswered != "undefined" &&
        filters.enteredAnswered != ""
      ) {
        params = params.includes("?")
          ? params + "&answer=" + filters.enteredAnswered
          : params + "?answer=" + filters.enteredAnswered;
      }
    }
    params=params.includes("?")? params +'&page='+ pages.currentPage : params + "?page=" + pages.currentPage
   
    let url = `company/` + params;
    Ws1.get(url)
      .then((response) => {
        //console.log(response.data);
        setCompanies(response.data.results);
        setPages ( {previousPage: response.data.previous,
          currentPage: pages.currentPage,
          nextPage: response.data.next});
        setAuthLoading(false);
        setLoading(false);
      })
      .catch((error) => {});
    setReloadData(false);
  }, [filters, reloadData,pages.currentPage]);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  const filterHandler = (newfilters) => {
    //console.log("test Ef");
    setFilters(newfilters);
  };

  const getConfirmation = async () => {
    const result = await confirm({
      title: "Delete Compaany",
      message:
        "Are you sure you want to delete this company? It will delete all it's related contacts",
      confirmColor: "danger",
      cancelText: "Cancel",
      confirmText: "Delete",
      size: "sm",
    });

    return result;
  };

  const deleteHandler = async (id) => {
    const result = await getConfirmation();
    if (result) {
      const Ws1 = Ws(true, token);
      Ws1.delete(`company/${id}/`)
        .then((response) => {
          //console.log(response);
          setAlertData({
            title: "Company Delete",
            message: "Company deleted successfully",
            type: "success",
          });
        })
        .catch((error) => {
          setAlertData({
            title: "Company Delete",
            message: "There was a problem deleting the company",
            type: "danger",
          });
          if (error.response.status === 400)
            setAlertData((prevState) => {
              return {
                ...prevState,
                message: prevState.message + ". " + error.response.data[0],
              };
            });
        });
      setReloadData(true);
      handleVisible();
    }
  };

  //alert

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
  };


  const addToListHandler = (companyids,newlist) => {
    const Ws1 = Ws(true, token);

    companyids.forEach((company_id, index) => {
      const url = "company/" + company_id + "/addlist/";
      Ws1.post(url, {
        list: newlist,
      })
        .then((response) => {
          //console.log(response.data);
          
        })
        .catch((error) => {
          setAlertData({
            title: "Company Add to List",
            message: "There was a problem adding the company to the list",
            type: "danger",
          });
          if (error.response.status === 400)
            setAlertData((prevState) => {
              return {
                ...prevState,
                message: prevState.message + ". " + error.response.data[0],
              };
            });
        });
    });
    
      setAlertData({
        title: "Company Add to List",
        message: "Company Added to the list successfully",
        type: "success",
      });
    handleVisible();
  };
  return (
    <div>
      <Alert
        title={alertData.title}
        type={alertData.type}
        isOpen={visibleAlert}
      >
        {alertData.message}
      </Alert>
      <CompanySearch
        onFilter={filterHandler}
        projects={projects}
        colors={colors}
        onHandleVisible={handleVisible}
        onsetAlertData={setAlertData}
      />
      <CompanyTable
        companies={companies}
        onDelete={deleteHandler}
        onAddToList={addToListHandler}
        pages={pages} onUpdatePage={setPages}
        onsetAlertData={setAlertData}
        onHandleVisible={handleVisible}
      ></CompanyTable>
    </div>
  );
}
export default Company;
