import React from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "../Headers/Header";
import AnswerItem from "./AnswerItem";
import * as ReactBootstrap from "react-bootstrap";
import Paginator from "../Commons/Paginator";

function AnswerTable(props) {

 
 
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Answer table</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Project</th>
                    <th scope="col">Company</th>
                    <th scope="col">Answer</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Contact Log</th>
                    
                  </tr>
                </thead>
                <tbody>
                {props.answers.map((Answer) => (
                  <AnswerItem
                    id={Answer.id}
                    key={Answer.id}
                    company_id={Answer.company_id}
                    company_name={Answer.company_name}
                    project_id={Answer.project_id}
                    project_name={Answer.project_name}
                    answer={Answer.answer}
                    answer_color={Answer.answer_color}
                    comments={Answer.comments}
                    contact_log={Answer.contact_log}
                    
                  ></AnswerItem>
                ))}
              </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                <Paginator pages={props.pages} onUpdatePages={props.onUpdatePage}></Paginator>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AnswerTable;
