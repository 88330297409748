import React, { useState, useEffect } from "react";
import "react-base-table/styles.css";

// import ColumnConfigMenu from "./ColumnConfigMenu/ColumnConfigMenu";
import {
  Column,
  SortOrder,
  AutoResizer,
  normalizeColumns,
  callOrReturn,
  unflatten,
  TableHeader as BaseTableHeader,
  TableRow as BaseTableRow,
} from "react-base-table";
import styled, { css, keyframes, createGlobalStyle } from "styled-components";

import SelectableTable from "./SelectableTable";

const action = (message) => (args) => console.log(message, args);

const Container = styled.div`
  width: 100%;
  height: calc(50vh + 220px);
`;

const defaultSort = { key: "name", order: SortOrder.ASC };
export default function BaseTable(props) {
  const [state, setState] = useState({
    data: props.data,
    sortBy: { defaultSort },
  });
  useEffect(() => {
    setState({
      data: props.data,
      sortBy: { defaultSort },
    });
  }, [props.data]);

  const getSelectedIds = (selected) => {
    props.getSelected(selected);
  };

  const onColumnSort = (sortBy) => {
    const order = state.sortBy.order === SortOrder.ASC ? 1 : -1;
    const data = [...state.data];
    data.sort((a, b) => (a[sortBy.key] > b[sortBy.key] ? order : -order));
    setState({
      sortBy: {
        key: sortBy.key,
        order: order == 1 ? SortOrder.DESC : SortOrder.ASC,
      },
      data,
    });
    //console.log(state);
  };
  const Empty = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
  `;

  return (
    <>
      <Container>
        <AutoResizer>
          {({ width, height }) => (
            <SelectableTable
              fixed
              width={width}
              height={height}
              selectable={props.selectable}
              estimatedRowHeight={40}
              columns={props.columns}
              data={state.data}
              sortState={state.sortBy}
              emptyRenderer={<Empty>No Information to Load</Empty>}
              onColumnSort={onColumnSort}
              onRowSelect={action("onRowSelect")}
              onSelectedRowsChange={getSelectedIds}
            />
          )}
        </AutoResizer>
      </Container>
    </>
  );
}
