import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken } from "./Common";
import Auth from "../components/layouts/Auth";

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {

  return (
    <Route
      {...rest}
      render={(props) =>
        !getToken() ? (
          <Auth><Component {...props} /></Auth>
        ) : (
          <Redirect to={{ pathname: "/project" }} />
        )
      }
    />
  );
}

export default PublicRoute;
