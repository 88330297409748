import React from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "../Headers/Header";
import ListItem from "./ListItem";
import * as ReactBootstrap from "react-bootstrap";
import Paginator from "../Commons/Paginator";

function ListTable(props) {
  const deleteListHandler = (list_id) => {
    // console.log("En TAble");
    // console.log(list_id);
    props.onDelete(list_id);
  };

  const shareListHandler = (list_id, newuser) => {
    // console.log("En TAble");
    // console.log(list_id);
    props.onShare(list_id, newuser);
  };

  const deleteCompanyHandler = (company, list) => {
    // console.log("En TAble");
    props.onDeleteCompany(company, list);
  };


  

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">List table</h3>
              </CardHeader>
              <ReactBootstrap.Accordion>
                {props.lists.map((list) => (
                  <ListItem
                    id={list.id}
                    key={list.id}
                    name={list.name}
                    user={list.user_name}
                    companies={list.companies}
                    users={props.users}
                    onDelete={deleteListHandler}
                    onShare={shareListHandler}
                    onCompanyDelete={deleteCompanyHandler}
                  ></ListItem>
                ))}
              </ReactBootstrap.Accordion>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    pages={props.pages}
                    onUpdatePages={props.onUpdatePage}
                  ></Paginator>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ListTable;
