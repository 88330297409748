import React, { useState } from "react";
import Ws from "./components/Axios/Axios";
import { setUserSession } from "./Utils/Common";
import Alert from "./components/Commons/Alert";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";

function Login(props) {
  let history=useHistory();
  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [visibleAlert, setAlertVisible] = useState(false); 

  const handleVisible = () => { 
      setAlertVisible(true)
      setTimeout(() => {
          setAlertVisible(false)
      }, 5000);
  } 

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    const Ws1 = Ws(false, null);
    Ws1.post("login/", { email: username.value, password: password.value })
      .then((response) => {
        setLoading(false);
        //console.log(response.data);
        setUserSession(response.data.token, response.data.user, response.data.id, response.data.is_staff);
        history.push("/project");
      })
      .catch((error) => {
        //console.log("entra catch");
        setLoading(false);
        handleVisible();
        
      });
  };

  return (
    <>
      
      <Col lg="5" md="7">
      <Alert title={"Autentication Failed"} type={'danger'} isOpen={visibleAlert} >User/Password incorrect</Alert>
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    {...username}
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    {...password}
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              {/*<div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>*/}
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  value={loading ? "Loading..." : "Login"}
                  onClick={handleLogin}
                  disabled={loading}
                >
                  Sign in
                </Button>
                <br/>
                {
                  loading? (<Spinner  size='sm'/>) : null
                }
                
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="/reset_password"
            >
              <small>Forgot password?</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
