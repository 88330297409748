import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Col,
  Media,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import { useState, useEffect } from "react";
import Ws from "../Axios/Axios";
import { getToken } from "../../Utils/Common";
import Paginator from "../Commons/Paginator";
import ActionButton from "./ActionButtons";
import { Column } from "react-base-table";
import AppModal from "../Commons/AppModal";
import Multiselect from "multiselect-react-dropdown";
//import styled from 'styled-components';

import styled, { css, keyframes, createGlobalStyle } from "styled-components";
import BaseTable1 from "../Commons/BaseTable";

function CompanyTable(props) {
  const [lists, setLists] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [idsToAdd, setIdsToAdd] = useState([]);
  const [token, setToken] = useState(getToken());
  const [newlist, setNewList] = useState();
  useEffect(() => {
    const Ws1 = Ws(true, token);
    Ws1.get(`list/`)
      .then((response) => {
        // console.log(response.data);
        setLists(response.data.results);
      })
      .catch((error) => {});
  }, []);

  const Score = styled.td`
    background-color: #${(props) => props.color};
  `;

  const addToListHandler = () => {
    toggle();
    props.onAddToList(idsToAdd, newlist);
  };
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  function onSelectNewCompanyList(selectedList, selectedItem) {
    // console.log(selectedItem);
    // console.log(selectedItem["id"]);
    setNewList(selectedItem["id"]);
  }

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      resizable: true,
      sortable: true,
      frozen: Column.FrozenDirection.LEFT,
      flexShrink: 0,
      flexGrow: 3,
      cellRenderer: ({ cellData: name }) => (
        <th scope="row">
          <Media className="align-items-center">
            <Media>
              <span className="mb-0 text-sm">{name}</span>
            </Media>
          </Media>
        </th>
      ),
    },
    {
      key: "country",
      title: "Country",
      dataKey: "country",
      width: 60,
      align: Column.Alignment.CENTER,
      sortable: false,
      resizable: true,
      //cellRenderer: ({ cellData: score }) => <p >{score}</p>,
    },
    {
      key: "description",
      title: "Description",
      dataKey: "description",
      width: 200,
      align: Column.Alignment.CENTER,
      resizable: true,
      sortable: true,
    },
    {
      key: "employee_number",
      title: "Employees",
      dataKey: "employee_number",
      width: 60,
      resizable: true,
      sortable: true,
      //cellRenderer: ({ cellData }) => <p>{cellData}</p>,
    },
    {
      key: "patents_numer",
      title: "Patents",
      dataKey: "patents_numer",
      width: 60,
      resizable: true,
      sortable: true,
    },
    {
      key: "answer",
      title: "Answer",
      dataKey: "answer",
      cellRenderer: ({ rowData }) => (
        <td style={{ backgroundColor: `#${rowData.answer_color}` }}>
          {rowData.answer}
        </td>
      ),
      width: 100,
      resizable: true,
      sortable: true,
    },
    {
      key: "comments",
      title: "Comments",
      dataKey: "comments",
      width: 200,
      resizable: true,
    },
    {
      key: "contact_log",
      title: "Contact Log",
      dataKey: "contact_log",
      width: 200,
      resizable: true,
    },
    {
      key: "link",
      title: ">",
      dataKey: "link",
      width: 100,
      align: Column.Alignment.CENTER,
      resizable: true,
      //headerRenderer: () => <p>?</p>,
      //cellRenderer: ({ cellData }) => <p>{cellData}</p>,
    },
    {
      key: "projects",
      title: "Projects",
      dataKey: "projects",
      width: 100,
      align: Column.Alignment.CENTER,
      resizable: true,
      //headerRenderer: () => <p>?</p>,
      cellRenderer: ({ rowData }) => (
        <span>
          {rowData.projects.map((project, index) => (
            <div key={index}>
              {" "}
              {index + 1}. {project.name} {"\n"}{" "}
            </div>
          ))}
        </span>
      ),
    },
    {
      key: "contacts",
      title: "Contacts",
      dataKey: "contacts",
      width: 100,
      align: Column.Alignment.CENTER,
      resizable: true,
      //headerRenderer: () => <p>?</p>,
      cellRenderer: ({ rowData }) => (
        <span>
          {rowData.contacts.map((contact, index) => (
            <div key={index}>
              {" "}
              {index + 1}. {contact.name} {"\n"}{" "}
            </div>
          ))}
        </span>
      ),
    },
    {
      key: "action",
      width: 100,
      align: Column.Alignment.CENTER,
      frozen: Column.FrozenDirection.RIGHT,
      cellRenderer: ({ rowData }) => (
        <ActionButton
          data={rowData}
          setID={setIdsToAdd}
          onDelete={deleteHandler}
          addToList={addToListHandler}
          toggle={toggle}
        />
      ),
    },
  ];

  const deleteHandler = (id) => {
    props.onDelete(id);
  };

  function getSelected(selectedItems) {
    setSelectedItems(selectedItems);
  }

  function addBulkHandler() {
    if (selectedItems.length == 0) {
      props.onsetAlertData({
        title: "Company",
        message: "There is no seleted company",
        type: "danger",
      });
      props.onHandleVisible();
    } else {
      // console.log("Si tiene");
      setIdsToAdd(selectedItems);
      toggle();
    }
  }

  return (
    <>
      {/* Page content */}
      <AppModal
        toggle={toggle}
        modal={modal}
        title={"Add to List"}
        handleSubmission={addToListHandler}
        action={"Add to List"}
      >
        <Row>
          <Col md="12">
            <label className="form-control-label">List</label>
            <Multiselect
              displayValue="name"
              onKeyPressFn={function noRefCheck() {}}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectNewCompanyList}
              options={lists}
              singleSelect={true}
              placeholder="Project"
              style={{
                multiselectContainer: {
                  // To change css for multiselect (Width,height,etc..)

                  borderRadius: "0.375rem",
                },
              }}
            />
          </Col>
        </Row>
      </AppModal>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div style={{ display: "flex" }}>
                  <div>
                    <h3 className="mb-0">Company table</h3>{" "}
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <Button
                      className="btn-outline-info "
                      id="sharebulk"
                      color="info"
                      type="button"
                      size="sm"
                      onClick={addBulkHandler}
                    >
                      <span className="btn-icon-only">
                        <i className="ni ni-fat-add"></i>
                      </span>
                    </Button>
                  </div>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target="sharebulk"
                >
                  Add to List in bulk
                </UncontrolledTooltip>
              </CardHeader>

              <BaseTable1
                columns={columns}
                data={props.companies}
                getSelected={getSelected}
                selectable={"selectable"}
              ></BaseTable1>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    pages={props.pages}
                    onUpdatePages={props.onUpdatePage}
                  ></Paginator>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CompanyTable;
