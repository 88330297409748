
import React, { useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
  } from "reactstrap";
  import Alert from "../Commons/Alert";
  import {
    getToken
  } from "../../Utils/Common";
  import Ws from "../Axios/Axios";
  import {
    useLocation,
    useHistory
  } from "react-router-dom";
  
  const ChangePassword = (props) => {
    const [newPassword, setNewPassword] = useState({
       
        Password: "",
        ConfirmationPassword: ""

    });
    const history = useHistory();
    let location = useLocation();
    const [token, setToken] = useState(new URLSearchParams(location.search).get("token"));
    // console.log(token)
    const [isConfirmationPasswordFilled, setIsConfirmationPasswordFilled] = useState(false);
    const [isPasswordFilled, setIsPasswordFilled] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    

    const [visibleAlert, setAlertVisible] = useState(false);
    
    const [alertData, setAlertData] = useState({
        title: "",
        message: "",
        type: "",
    });

      const changeNewConfirmationPasswordHandler = (event) => {
        setNewPassword((prevState) => {
          return { ...prevState, ConfirmationPassword: event.target.value };
        });
        if (event.target.value.trim().length > 0) {
          setIsConfirmationPasswordFilled(true);
          setShowErrors(false);
        } else {
            
          setIsConfirmationPasswordFilled(false);
          setShowErrors(true);
        }
      };
      const changeNewPasswordHandler = (event) => {
        setNewPassword((prevState) => {
          return { ...prevState, Password: event.target.value };
        });
        if (event.target.value.trim().length > 0) {
            setIsPasswordFilled(true);
          setShowErrors(false);
        } else {
            setIsPasswordFilled(false);
          setShowErrors(true);
        }
      };

      const handleSubmission = () => {
        if (
          !isConfirmationPasswordFilled ||
          !isPasswordFilled
        ) {
          setShowErrors(true);
          return;
        }

        if (newPassword.Password != newPassword.ConfirmationPassword)
        {
          setShowErrors(true);
          setAlertData({
            title: "Change Password",
            message: "Passwords do not match",
            type: "danger",
          });
          handleVisible();
          return;
        }

        if (token ==null)
        {
          setAlertData({
            title: "Change Password",
            message: "Cannot Perform Password Update - no token detected",
            type: "danger",
          });
          handleVisible();
          return;
        }
    
        const Ws1 = Ws(false, token);
        Ws1.post("password_reset/confirm/", {
          token: token,
          password: newPassword.Password
        })
          .then((response) => {
            // console.log(response.data);
            setNewPassword({
              ConfirmationPassword: "",
                Password: ""
               
            });
            setAlertData({
              title: "Change Password",
              message: "Password Changed correctly",
              type: "success",
            });
          })
          .catch((error) => {
            // console.log(error);
            // console.log(error.response);
            // console.log(error.response.data);
            // console.log(error.response.data["non_field_errors"]);
            // console.log(Object.getOwnPropertyNames(error.response.data));
            let response = "";
            if (error.response.data[0]) response = error.response.data[0];
            
            if (error.response.data["non_field_errors"])
              response = error.response.data["non_field_errors"];
              
            if (error.response.data["email"])
              response = error.response.data["email"];
    
              setAlertData({
              title: "Change Password",
              message: "There was a problem updating the password",
              type: "danger",
            });
            if (error.response.status === 400)
            setAlertData((prevState) => {
                return {
                  ...prevState,
                  message: [...(prevState.message + ". " + response)],
                };
              });
          });
        
          handleVisible(true);
      };
      const handleVisible = (redirect=false) => {
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
          if (redirect)
            history.push("/login");
        }, 10000);
      };
    return (
      <>
     
        <Col lg="6" md="8">
        <Alert
        title={alertData.title}
        type={alertData.type}
        isOpen={visibleAlert}
      >
        {alertData.message}
      </Alert>
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2">
              <p>Change password</p>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      onChange={changeNewPasswordHandler}
                      className={!isPasswordFilled & showErrors ? "is-invalid" : ""}
                      value={newPassword.Password}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      autoComplete="new-password"
                      onChange={changeNewConfirmationPasswordHandler}
                      className={!isConfirmationPasswordFilled & showErrors ? "is-invalid" : ""}
                      value={newPassword.ConfirmationPassword}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={handleSubmission}>
                    Change Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  };
  
  export default ChangePassword;
  