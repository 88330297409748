import React from "react";
//import ProjectFilter from "./Project/ProjectFilter"
import ContactTable from "./ContactTable";
import ContactSearch from "./ContactSearch";
import Ws from "../Axios/Axios";
import confirm from "reactstrap-confirm";
import { useState, useEffect } from "react";
import {
  getToken,
  getUser,
  removeUserSession,
  setUserSession,
} from "../../Utils/Common";
import Alert from "../Commons/Alert";

function Contact(props) {
  const [authLoading, setAuthLoading] = useState(true);
  const [contacts, setContacts] = useState([{}]);
  const [filters, setFilters] = useState([{}]);
  const [companies, setCompanies] = useState([{}]);
  
  const [token, setToken] = useState(getToken());
  const [visibleAlert, setAlertVisible] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [alertData, setAlertData] = useState({
    title: "",
    message: "",
    type: "",
  });

  const [pages, setPages] = useState({
    previousPage: null,
    currentPage: 1,
    nextPage: null
  });
  if (!token) {
    return;
  }

  useEffect(() => {
    
    const Ws1 = Ws(true, token);
    Ws1.get(`company/?page=get_all`)
      .then((response) => {
        // console.log(response.data);
        setCompanies(response.data);
      })
      .catch((error) => {});
  }, []);

  const getContacts = (params) =>{
    params=params.includes("?")? params +'&page='+ pages.currentPage : params + "?page=" + pages.currentPage
    let url = `contact/` + params;

    const Ws1 = Ws(true, token);
    Ws1.get(url)
      .then((response) => {
        // console.log(response.data);
        setContacts(response.data.results);
        setPages ( {previousPage: response.data.previous,
          currentPage: pages.currentPage,
          nextPage: response.data.next});
        setAuthLoading(false);
        setLoading(false);
      })
      .catch((error) => {});

  }

  useEffect(() => {
    
    let params = "";
    if (
      !(Object.keys(filters).length === 0 && filters.constructor === Object)
    ) {
      if (
        typeof filters.enteredPosition != "undefined" &&
        filters.enteredPosition != ""
      ) {
        params = params.includes("?")
          ? params + "&position=" + filters.enteredPosition
          : params + "?position=" + filters.enteredPosition;
      }

      if (
        typeof filters.enteredEmail != "undefined" &&
        filters.enteredEmail != ""
      ) {
        params = params.includes("?")
          ? params + "&email=" + filters.enteredEmail
          : params + "?email=" + filters.enteredEmail;
      }
      if (
        typeof filters.enteredPhone != "undefined" &&
        filters.enteredPhone != ""
      ) {
        params = params.includes("?")
          ? params + "&phone=" + filters.enteredPhone
          : params + "?phone=" + filters.enteredPhone;
      }
      if (
        typeof filters.enteredName != "undefined" &&
        filters.enteredName != ""
      ) {
        params = params.includes("?")
          ? params + "&name=" + filters.enteredName
          : params + "?name=" + filters.enteredName;
      }
      if (
        typeof filters.enteredCompany != "undefined" &&
        filters.enteredCompany != ""
      ) {
        params = params.includes("?")
          ? params + "&company=" + filters.enteredCompany
          : params + "?company=" + filters.enteredCompany;
      }
    }
    getContacts(params);
    setReloadData(false);
    
  }, [filters,reloadData,pages.currentPage]);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  const filterHandler = (newfilters) => {
    // console.log(newfilters);
    setFilters(newfilters);
  };

  const getConfirmation = async () => {
    const result = await confirm({
      title: "Delete Contact",
      message: "Are you sure you want to delete this contact?",
      confirmColor: "danger",
      cancelText: "Cancel",
      confirmText: "Delete",
      size: "sm",
    });
    
    return result;
  };

  const deleteHandler = async (id) => {
    const result = await getConfirmation();
    if (result) {
      const Ws1 = Ws(true, token);
      Ws1.delete(`contact/${id}/`)
        .then((response) => {
          // console.log(response);
          setAlertData({
            title: "Contact Delete",
            message: "Contact deleted successfully",
            type: "success",
          });
        })
        .catch((error) => {
          setAlertData({
            title: "Contact Delete",
            message: "There was a problem deleting the contact",
            type: "danger",
          });
          if (error.response.status === 400)
            setAlertData((prevState) => {
              return {
                ...prevState,
                message: prevState.message + ". " + error.response.data[0],
              };
            });
        });
        setReloadData(true);
        handleVisible();
    }
  };

  //alert
  
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
  };
  return (
    <div>
      <Alert
          title={alertData.title}
          type={alertData.type}
          isOpen={visibleAlert}
        >
          {alertData.message}
        </Alert>
      <ContactSearch onFilter={filterHandler} companies={companies} onHandleVisible={handleVisible} onsetAlertData={setAlertData}/>
      <ContactTable contacts={contacts} pages={pages} onUpdatePage={setPages} onDelete={deleteHandler} ></ContactTable>
    </div>
  );
}

export default Contact;
