import React, { useState } from "react";

import { Button } from "reactstrap";

function ListItem(props) {
  const deleteCompanyHandler = () => {
    props.onCompanyDelete(props.id);
  };
  return (
    <>
      <tr key={props.id}>
        <td>{props.name}</td>
        <td>
          <Button
            className="btn-icon btn-1"
            id="delete"
            color="danger"
            type="button"
            size="sm"
            onClick={deleteCompanyHandler}
          >
            <span className="btn-icon-only">
              <i className="ni ni-fat-remove"></i>
            </span>
          </Button>
        </td>
      </tr>
    </>
  );
}

export default ListItem;
