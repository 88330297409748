import React from "react";
//import ProjectFilter from "./Project/ProjectFilter"
import ProjectTable from "./ProjectTable";
import ProjectSearch from "./ProjectSearch";
import Ws from "../Axios/Axios";
import { useState, useEffect } from "react";
import Alert from "../Commons/Alert";
import confirm from "reactstrap-confirm";

import {
  getToken,
  getUser,
  removeUserSession,
  setUserSession,
} from "../../Utils/Common";

function Project(props) {
  const [authLoading, setAuthLoading] = useState(true);
  const [projects, setProjects] = useState([{}]);
  const [filters, setFilters] = useState([{}]);
  const [users, setUsers] = useState([{}]);

  const [token, setToken] = useState(getToken());
  const [visibleAlert, setAlertVisible] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [alertData, setAlertData] = useState({
    title: "",
    message: "",
    type: "",
  });

  const [pages, setPages] = useState({
    previousPage: null,
    currentPage: 1,
    nextPage: null,
  });

  if (!token) {
    return;
  }

  useEffect(() => {
    const Ws1 = Ws(true, token);
    Ws1.get(`profile/?page=get_all&isactive=true`)
      .then((response) => {
        // console.log(response.data);
        setUsers(response.data);
      })
      .catch((error) => {});
  }, []);

  const getProjects = () => {
    let params = "";
    if (
      !(Object.keys(filters).length === 0 && filters.constructor === Object)
    ) {
      if (
        typeof filters.enteredUser != "undefined" &&
        filters.enteredUser != ""
      ) {
        params = params.includes("?")
          ? params + "&user=" + filters.enteredUser
          : params + "?user=" + filters.enteredUser;
      }

      if (
        typeof filters.enteredName != "undefined" &&
        filters.enteredName != ""
      ) {
        params = params.includes("?")
          ? params + "&name=" + filters.enteredName
          : params + "?name=" + filters.enteredName;
      }
    }
    params = params.includes("?")
      ? params + "&page=" + pages.currentPage
      : params + "?page=" + pages.currentPage;
    let url = `project/` + params;

    const Ws1 = Ws(true, token);
    Ws1.get(url)
      .then((response) => {
        // console.log(response.data);
        setProjects(response.data.results);
        setPages({
          previousPage: response.data.previous,
          currentPage: pages.currentPage,
          nextPage: response.data.next,
        });
        setAuthLoading(false);
        setLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getProjects();
    setReloadData(false);
  }, [filters, reloadData, pages.currentPage]);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  const filterHandler = (newfilters) => {
    // console.log(newfilters);
    setFilters(newfilters);
  };

  const getConfirmation = async () => {
    const result = await confirm({
      title: "Delete Project",
      message: "Are you sure you want to delete this project?",
      confirmColor: "danger",
      cancelText: "Cancel",
      confirmText: "Delete",
      size: "sm",
    });

    return result;
  };

  const deleteHandler = async (id) => {
    const result = await getConfirmation();
    if (result) {
      const Ws1 = Ws(true, token);
      Ws1.delete(`project/${id}/`)
        .then((response) => {
          // console.log(response);
          setAlertData({
            title: "Project Delete",
            message: "Project deleted successfully",
            type: "success",
          });
        })
        .catch((error) => {
          setAlertData({
            title: "Project Delete",
            message: "There was a problem deleting the project",
            type: "danger",
          });
          if (error.response.status === 400)
            setAlertData((prevState) => {
              return {
                ...prevState,
                message: prevState.message + ". " + error.response.data[0],
              };
            });
        });
      setReloadData(true);
      handleVisible();
    }
  };
  // alert

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
  };
  return (
    <div>
      <Alert
        title={alertData.title}
        type={alertData.type}
        isOpen={visibleAlert}
      >
        {alertData.message}
      </Alert>
      <ProjectSearch
        onFilter={filterHandler}
        users={users}
        onUpdatePage={setPages}
        onHandleVisible={handleVisible}
        onsetAlertData={setAlertData}
      />
      <ProjectTable
        projects={projects}
        pages={pages}
        onUpdatePage={setPages}
        onDelete={deleteHandler}
      ></ProjectTable>
    </div>
  );
}

export default Project;
