import React from "react";
//import ProjectFilter from "./Project/ProjectFilter"
import AnswerTable from "./AnswerTable";
import AnswerSearch from "./AnswerSearch";
import Ws from "../Axios/Axios";
import { useState, useEffect } from "react";
import {
  getToken,
  getUser,
  removeUserSession,
  setUserSession,
} from "../../Utils/Common";
import Alert from "../Commons/Alert";
import confirm from "reactstrap-confirm";

function Answer(props) {
  const [authLoading, setAuthLoading] = useState(true);
  const [projects, setProjects] = useState([{}]);
  const [companies, setCompanies] = useState([{}]);
  const [answers, setAnswers] = useState([{}]);
  const [filters, setFilters] = useState([{}]);
  const [users, setUsers] = useState([{}]);
  const [reloadData, setReloadData] = useState(false);
  const [visibleAlert, setAlertVisible] = useState(false); 
  
  const [token, setToken] = useState(getToken());
  const [alertData, setAlertData] = useState({
    title: '',
    message: '',
    type: ''
  });

  const [pages, setPages] = useState({
    previousPage: null,
    currentPage: 1,
    nextPage: null
  });

  if (!token) {
    return;
  }


  useEffect(() => {
    const Ws1 = Ws(true, token);
    Ws1.get(`profile/?page=get_all&isactive=true`)
      .then((response) => {
        //console.log(response.data);
        setUsers(response.data);
      })
      .catch((error) => {});
      Ws1.get(`project/?page=get_all`)
      .then((response) => {
        //console.log(response.data);
        setProjects(response.data);
      })
      .catch((error) => {});
      Ws1.get(`company/?page=get_all`)
      .then((response) => {
        //console.log(response.data);
        setCompanies(response.data);
      })
      .catch((error) => {});
  }, []);

 

  useEffect(() => {
    
    let params = "";
    if (
      !(Object.keys(filters).length === 0 && filters.constructor === Object)
    ) {
      if (
        typeof filters.enteredProjects != "undefined" &&
        filters.enteredProjects != ""
      ) {
        params = params.includes("?")
          ? params + "&project=" + filters.enteredProjects
          : params + "?project=" + filters.enteredProjects;
      }
      if (
        typeof filters.enteredCompanies != "undefined" &&
        filters.enteredCompanies != ""
      ) {
        params = params.includes("?")
          ? params + "&company=" + filters.enteredCompanies
          : params + "?company=" + filters.enteredCompanies;
      }
    }
    params=params.includes("?")? params +'&page='+ pages.currentPage : params + "?page=" + pages.currentPage
    let url = `answer/` + params;

    const Ws1 = Ws(true, token);
    Ws1.get(url)
      .then((response) => {
        //console.log(response.data);
        setAnswers(response.data.results);
        setPages ( {previousPage: response.data.previous,
          currentPage: pages.currentPage,
          nextPage: response.data.next});
        setAuthLoading(false);
        setLoading(false);
      })
      .catch((error) => {});
      setReloadData(false);
  }, [filters,reloadData,pages.currentPage]);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  const filterHandler = (newfilters) => {
    //console.log(newfilters);
    setFilters(newfilters);
  };

  // alert
  
  

  const handleVisible = () => { 
    setAlertVisible(true)
    setTimeout(() => {
        setAlertVisible(false)
    }, 5000);
  } 

  const getConfirmation = async () => {
    const result = await confirm({
      title: "Delete Answer",
      message: "Are you sure you want to delete this Answer?",
      confirmColor: "danger",
      cancelText: "Cancel",
      confirmText: "Delete",
      size: "sm",
    });
    
    return result;
  };

  const deleteHandler = async (id) => {
    const result = await getConfirmation();
    if (result) {
      const Ws1 = Ws(true, token);
      Ws1.delete(`Answer/${id}/`)
        .then((response) => {
          //console.log(response);
          setAlertData({
            title: "Answer Delete",
            message: "Answer deleted successfully",
            type: "success",
          });
        })
        .catch((error) => {
          setAlertData({
            title: "Answer Delete",
            message: "There was a problem deleting the Answer",
            type: "danger",
          });
          if (error.response.status === 400)
            setAlertData((prevState) => {
              return {
                ...prevState,
                message: prevState.message + ". " + error.response.data[0],
              };
            });
        });
        setReloadData(true);
        handleVisible();
    }
  };
  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }
  
  
  return (
    <div>
      <Alert
          title={alertData.title}
          type={alertData.type}
          isOpen={visibleAlert}
        >
          {alertData.message}
        </Alert>
      <AnswerSearch onFilter={filterHandler} projects={projects} companies={companies} onsetAlertData={setAlertData} onHandleVisible={handleVisible}/>
      <AnswerTable answers={answers} users={users}  pages={pages} onUpdatePage={setPages} onDelete={deleteHandler} ></AnswerTable>
    </div>
  );
}

export default Answer;
