import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Media,
} from "reactstrap";
// core components


import Paginator from "../Commons/Paginator";
import BaseTable1 from "../Commons/BaseTable";
import { Column } from "react-base-table";
import ActionButton from "./ActionButtons";

function ProjectTable(props) {
  const deleteProjectHandler = (project_id) => {
    // console.log("En TAble");
    // console.log(project_id);
    props.onDelete(project_id);
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      resizable: true,
      sortable: true,
      frozen: Column.FrozenDirection.LEFT,
      flexShrink: 0,
      flexGrow: 3,
      cellRenderer: ({ cellData: name }) => (
        <th scope="row">
          <Media className="align-items-center">
            <Media>
              <span className="mb-0 text-sm">{name}</span>
            </Media>
          </Media>
        </th>
      ),
    },
    {
      key: "user",
      title: "User",
      dataKey: "user_name",
      width: 200,
      align: Column.Alignment.CENTER,
      sortable: false,
      resizable: true,
      //cellRenderer: ({ cellData: score }) => <p >{score}</p>,
    },
    {
      key: "action",
      width: 100,
      align: Column.Alignment.CENTER,
      frozen: Column.FrozenDirection.RIGHT,
      cellRenderer: ({ rowData }) => (
        <ActionButton data={rowData} onDelete={deleteProjectHandler} />
      ),
    },
  ];
  return (
    <>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Project table</h3>
              </CardHeader>

              <BaseTable1
                columns={columns}
                data={props.projects}
                //getSelected={getSelected}
              ></BaseTable1>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    pages={props.pages}
                    onUpdatePages={props.onUpdatePage}
                  ></Paginator>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ProjectTable;
