import React from "react";
//import ProjectFilter from "./Project/ProjectFilter"

import { 
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";

function Paginator(props) {
  //onUpdatePages
  
  const onPreviousPage =()=>{
    props.onUpdatePages ( {
      currentPage: props.pages.previousPage });

  }
  const onNextPage =(page)=>{
    props.onUpdatePages ( {
      currentPage: props.pages.nextPage});

  }
  

  return (
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      {props.pages.previousPage ? (
        <>
          <PaginationItem>
            <PaginationLink
              onClick={onPreviousPage}
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem >
            <PaginationLink  onClick={onPreviousPage}>
            {props.pages.previousPage}
            </PaginationLink>
          </PaginationItem>
        </>
      ) : (
        <></>
      )}
      <PaginationItem className="active">
        <PaginationLink onClick={(e) => e.preventDefault()}>
          {props.pages.currentPage} <span className="sr-only">(current)</span>
        </PaginationLink>
      </PaginationItem>
      {props.pages.nextPage ? (<>
      <PaginationItem>
        <PaginationLink onClick={onNextPage}>
          {props.pages.nextPage}
        </PaginationLink>
      </PaginationItem>

      
        <PaginationItem>
          <PaginationLink  onClick={onNextPage}>
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem></>
      ) : (
        <></>
      )}
    </Pagination>
  );
}

export default Paginator;
