// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }

  export const getUserId = () => {
    const userIdStr = sessionStorage.getItem('id');
    if (userIdStr) return JSON.parse(userIdStr);
    else return null;
  }

  export const getUserStaff = () => {
    const userIsStaffStr = sessionStorage.getItem('is_staff');
    if (userIsStaffStr) return JSON.parse(userIsStaffStr);
    else return null;
  }
   
   
  // return the token from the session storage
  export const getToken = () => {
    //return sessionStorage.getItem('token') || null;
    const userTokenstr = sessionStorage.getItem('token');
    if (userTokenstr) return userTokenstr;
    else return null;
  }
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('is_staff');
    sessionStorage.removeItem('id');
  }
   
  // set the token and user from the session storage
  export const setUserSession = (token, user,id,is_staff) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('is_staff', JSON.stringify(is_staff));
    sessionStorage.setItem('id', JSON.stringify(id));
  }
  